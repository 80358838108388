import Vue from 'vue';
import VueResource from 'vue-resource';
Vue.use(VueResource);

import {convertDateByLang, isInPlanList} from '../helper-functions/helper-functions';
import { ibSpinOpts2 } from '../helper-functions/applicationCommonValues';

const hccConfig = {
    planId: window.ibJS ? ibJS.planId || (ibJS.application && ibJS.application.planId) : '',
    //Plan Lists
    allExchangeGuardPlans: [...[599,600], ...[712,713]], // mergeing hccmis old and new plan
    studentSecurePlans: [...[561,453,454,455], ...[712,713]],
    //Plan List Functions
    isStudentSecure: function() { return isInPlanList(this.studentSecurePlans, this.planId) },
    isExchangeGuard: function() { return isInPlanList(this.allExchangeGuardPlans, this.planId) }
};

/*
Formatting

*** create very service a named function. ***
*** leave a two spaced gap in between every function. ***
*** add the function name to the export object at the bottom of the file. ***

 */
async function createUpdateQuoteService(apiUrl, data, storageKey, redirectUrl) {
    var self = this;
    self.$emit('resubmit');

    setTimeout(() => {
        if (self.formSending) createQuoteSpinner();
    }, 3000);

    // Spinner
    var spinTarget = self.$refs.btnspinner;
    var spinOpts = global.ibSpinOpts = {
      lines: 7 // The number of lines to draw
    , length: 21 // The length of each line
    , width: 6 // The line thickness
    , radius: 15 // The radius of the inner circle
    , scale: 0.3 // Scales overall size of the spinner
    , corners: 1 // Corner roundness (0..1)
    , color: '#fff' // #rgb or #rrggbb or array of colors
    , opacity: 0.45 // Opacity of the lines
    , rotate: 0 // The rotation offset
    , direction: 1 // 1: clockwise, -1: counterclockwise
    , speed: 1.4 // Rounds per second
    , trail: 60 // Afterglow percentage
    , fps: 20 // Frames per second when using setTimeout() as a fallback for CSS
    , zIndex: 2e9 // The z-index (defaults to 2000000000)
    , className: 'spinner' // The CSS class to assign to the spinner
    , top: '50%' // Top position relative to parent
    , left: '50%' // Left position relative to parent
    , shadow: false // Whether to render a shadow
    , hwaccel: true // Whether to use hardware acceleration
    , position: 'absolute' // Element positioning
    };

    var spinner = new IBSpinner(spinOpts).spin(spinTarget);
    self.formSending = true;

    if(window.globalLanguage.lang == 'zh' && data.startDate && data.endDate){
        let date = data.startDate.split('-');
        data.startDate = date[1] + '/' + date[2] + '/' + date[0];
        let endDate = data.endDate.split('-');
        data.endDate = endDate[1] + '/' + endDate[2] + '/' + endDate[0];
        if(self.$v && self.$v.form && self.$v.form.startDate){
            self.$v.form.startDate.$reset();
            self.$v.form.endDate.$reset();
        }
    }else if(window.globalLanguage.lang == 'es' && data.startDate && data.endDate){
        let date = data.startDate.split('/');
        data.startDate = date[1] + '/' + date[0] + '/' + date[2];
        let endDate = data.endDate.split('/');
        data.endDate = endDate[1] + '/' + endDate[0] + '/' + endDate[2];
        if(self.$v && self.$v.form && self.$v.form.startDate){
            self.$v.form.startDate.$reset();
            self.$v.form.endDate.$reset();
        }
    }
    try{
        window.sessionStorage[storageKey] = JSON.stringify(data);
    } catch(e) {
        console.log("Session Storage Not accesible")
    }

    if (typeof data.spouseAge != 'undefined' && (data.spouseAge == null || data.spouseAge == '')) {
        // setting spouseAge since null defaults to 0 on the back-end
        // Ravi is checking to see if he can properly handle null values
        data.spouseAge = -1;
        if(self.$v && self.$v.form){
            self.$v.form.spouseAge.$reset();
        }
    }
    var withLangUrl = apiUrl + "?lang=" + window.globalLanguage.lang;
    await self.$http.post(withLangUrl, data)
    .then(
        function (response) {
            if(response === undefined || response.body === undefined) {
                console.log("Response Error");
                return;
            }

            var responseBody = response.body;

            if (typeof responseBody === 'string') {
                try {
                    responseBody = JSON.parse(responseBody);
                } catch(error) {
                    console.log(error);
                    self.formSending = false;
                    return;
                }
            }

            if (responseBody.status === 'error') {
                var errors = {};
                hideQuoteSpinner();

                if(responseBody.data) {
                    if (responseBody.data.noPlansFound) {
                        if(self.showNoPlansFoundError && typeof(self.showNoPlansFoundError) == 'function') {
                            self.showNoPlansFoundError(responseBody.data?.fullQuoteId);
                        } else {
                            self.$emit('noplansfound', responseBody.data?.fullQuoteId);
                        }
                    } else {
                        if (typeof self.$parent.hideNoPlansFoundError === 'function') {
                            self.$parent.hideNoPlansFoundError(responseBody.data?.fullQuoteId);
                        } else if (typeof self.hideNoPlansFoundError === 'function') {
                            self.hideNoPlansFoundError(responseBody.data?.fullQuoteId);
                        }
                    }

                    if (responseBody.data.remarks) {
                        self.$root.$emit('loadremarks', responseBody.data.remarks);
                    }

                    if (responseBody?.data?.globalErrors) {
                        errors.globalErrors = responseBody.data.globalErrors;
                        // if (ibJS.section == 'trip' && errors.globalErrors && errors.globalErrors.length && errors.globalErrors[0].message.indexOf('365 days') > -1) {
                            if(!responseBody.data.hasOwnProperty('noPlansFound')) self.$emit('tripdayserror', errors);
                        // }
                    }
                    if (responseBody.data.fieldErrors) {
                        errors.fieldErrors = responseBody.data.fieldErrors;
                    }

                    if(responseBody.data.costaRicaRedirect) {
                        window.location.href = window.ibJS.costaRicaLanding;
                    }
                }
                if(window.globalLanguage.lang == 'zh' && data.startDate && data.endDate){
                    var startDate = new Date(data.startDate);
                    var formatDate = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate();
                    data.startDate = formatDate;
                    var endDate = new Date(data.endDate);
                    var formatEndDate = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();
                    data.endDate = formatEndDate;
                }else if(window.globalLanguage.lang == 'es' && data.startDate && data.endDate){
                    var startDate = new Date(data.startDate);
                    var formatDate = startDate.getDate() + '/' + (startDate.getMonth() + 1) + '/' +startDate.getFullYear();
                    data.startDate = formatDate;
                    var endDate = new Date(data.endDate);
                    var formatEndDate = endDate.getDate() + '/' + (endDate.getMonth() + 1) + '/' + endDate.getFullYear();
                    data.endDate = formatEndDate;
                }
                self.$emit('backenderrors', errors);

                if(this.scrollElementForQuoteError) {
                    this.$nextTick(() => {
                        document.querySelector(this.scrollElementForQuoteError)?.scrollIntoView();
                    });
                } else {
                    $("html, body").animate({ scrollTop: 0 }, "fast");
                }

                if(this.isResultsPage) { 
                    self.$emit('clear-plan-lists');
                }
                hideQuoteSpinner();
            } else {
                if(this.isResultsPage){
                    this.$emit('reset-narrow-filter');
                }
                try {
                    const pageType = window.globalLanguage.section;
                    const fbEvent = {
                        "content_ids": [`${pageType}-${window.globalLanguage.lang}`],
                        "content_type": "product_group",
                        "currency": "USD"
                    };
                    fbq('track', 'Lead', fbEvent);
                } catch (error) {
                    console.error('error' , error);
                }
                if(typeof responseBody.data != 'undefined' && typeof responseBody.data.redirectTo != 'undefined'){
                    console.log("responseBody.data.redirectTo: " + responseBody.data.redirectTo);
                    redirectUrl = responseBody.data.redirectTo;
                }
                if (window.globalLanguage.section === 'visitorUSA' && !ibJS.isResultsPage && vwoType == 'v1') {
                    redirectUrl += redirectUrl.indexOf('?') > -1 ? '&v=1' : '?v=1';
                }
                window.location.href = redirectUrl;
            }
            self.formSending = false;
            
        },
        function (response) {
            if(response === undefined || response.body === undefined) {
                console.log("Response Error");
                return;
            }

            var responseBody = response.body;
            var errors = {};
            if(responseBody !== undefined && responseBody !== null) {
                errors.serverErrors = responseBody.data;
                self.$emit('backend500error', errors);
                $("body").animate({ scrollTop: 0 }, "fast");
            }
            self.formSending = false;
            hideQuoteSpinner();
        }
    );
}

function createQuoteSpinner() {
    var spinWrapper = document.getElementById('extra-time');
    if(!spinWrapper) return;
    spinWrapper.style.display = "block";
    $('body').addClass('is-Locked');
    var spinComponent = document.getElementById('spin-target-extra-time');
    var spinner = new IBSpinner(ibSpinOpts2).spin(spinComponent);
}

function hideQuoteSpinner() {
    var spinWrapper = document.getElementById('extra-time');
    if(!spinWrapper) return;
    spinWrapper.style.display = "none";
    $('body').removeClass('is-Locked');

}

function initializeFormFromStorage(storageKey, callback) {
    try{
        if (window.sessionStorage[storageKey]) {
            var sessData = JSON.parse(window.sessionStorage[storageKey]);

            if(sessData) {
                for (let key in sessData) {
                    if (typeof this.form[key] !== 'undefined') {
                        this.form[key] = sessData[key];
                    }
                }
            }

            if (typeof this.loadedFromSession != 'undefined') this.loadedFromSession = true;
            if (callback) {
                callback();
            }
        }
    } catch(e){
        console.log("Session Storage is not accesible");
    }
}


function checkExpiredAndTriggerValidation() {
    try {
        if (window.sessionStorage.expired == 'true') {
            var sessData = JSON.parse(window.sessionStorage[window.globalLanguage.sectionCriteriaKey]);
            this.$v.$touch();
            if(sessData.spouseAge && sessData.spouseAge == -1){
                this.$v.form.spouseAge.$reset();
            }
            if(typeof sessData.showNumChildren != 'undefined' && !sessData.showNumChildren){
                this.$v.form.numChildren10_17.$reset();
                this.$v.form.numChildrenUnder10.$reset();
            }
            if(typeof this.$v.form.medicareAttest != 'undefined'){
                this.$v.form.medicareAttest.$reset();
            }
            window.sessionStorage.expired = '';
        }
    } catch(e) {
        console.log("Session Storage is not accesible");
    }
}

/**
 * for step1
 * if default is one traveler pass the last parameter as true
 */
function createUpdateApplicationService(apiUrl, data, storageKey, keepDefaultTraveler) {

    const hccConfig = {
        planId: window.ibJS.planId || (window.ibJS.application && window.ibJS.application.planId),
        //Plan Lists
        allExchangeGuardPlans: [...[599, 600], ...[712,713]],
        studentSecurePlans: [...[561, 453, 454, 455], ...[718, 719, 720, 721]],
        excGuardGrpPlans: [731,732],
        //Plan List Functions
        isStudentSecure: function () {
            return isInPlanList(hccConfig.studentSecurePlans, hccConfig.planId)
        },
        isExchangeGuard: function () {
            return isInPlanList(hccConfig.allExchangeGuardPlans, hccConfig.planId)
        },
        isExchangeGuardGrp: function() { 
            return isInPlanList(hccConfig.excGuardGrpPlans, hccConfig.planId) 
        }
    };

    var self = this;
    self.$emit('resubmit');
    // Spinner
    var spinTarget = self.$refs.btnspinner;
    var spinOpts = global.ibSpinOpts = {
      lines: 7 // The number of lines to draw
    , length: 21 // The length of each line
    , width: 6 // The line thickness
    , radius: 15 // The radius of the inner circle
    , scale: 0.3 // Scales overall size of the spinner
    , corners: 1 // Corner roundness (0..1)
    , color: '#fff' // #rgb or #rrggbb or array of colors
    , opacity: 0.45 // Opacity of the lines
    , rotate: 0 // The rotation offset
    , direction: 1 // 1: clockwise, -1: counterclockwise
    , speed: 1.4 // Rounds per second
    , trail: 60 // Afterglow percentage
    , fps: 20 // Frames per second when using setTimeout() as a fallback for CSS
    , zIndex: 2e9 // The z-index (defaults to 2000000000)
    , className: 'spinner' // The CSS class to assign to the spinner
    , top: '50%' // Top position relative to parent
    , left: '50%' // Left position relative to parent
    , shadow: false // Whether to render a shadow
    , hwaccel: true // Whether to use hardware acceleration
    , position: 'absolute' // Element positioning
    };

    var spinner = new IBSpinner(spinOpts).spin(spinTarget);
    self.formSending = true;
    self.$http.post(apiUrl, data)
    .then(function (response) {

    // Refactored in repsonse to the APP-241 fix
    if(response && response.body){
        var responseBody = response.body;

        // Parse as an Object if response is a string

        if (typeof responseBody === 'string') {
                responseBody = JSON.parse(responseBody);
            }

        // Handle the error first
        if (responseBody && responseBody.status && responseBody.status === 'error') {
                var errors = {};

                if(responseBody.data) {
                    if (responseBody?.data?.globalErrors) {
                        errors.globalErrors = responseBody.data.globalErrors;
                    }
                    if (responseBody.data.fieldErrors) {
                        errors.fieldErrors = responseBody.data.fieldErrors;
                    }
                }
                self.$emit('backenderrors', errors);
                self.formSending = false;


                $('html, body').animate({scrollTop: 0 }, 'fast');
                if(ibJS.currentStep == 1){

                    function newTraveler (age, tripCost, quotePrice, travelerType) {
                        this.age = age;
                        this.tripCost = tripCost;
                        this.quotePrice = quotePrice;
                        this.travelerType = travelerType;
                    }

                    if(self.form.travelerInfos && self.form.travelerInfos.length === 1){
                        let type1 = self.form.planId == 714 || ibJS.planConfig?.companyCode === 'IMG' || ibJS.planConfig?.companyCode === 'ARS' ? 'spouse':'',
                            type2 = self.form.planId == 714 || ibJS.planConfig?.companyCode === 'IMG' || ibJS.planConfig?.companyCode === 'ARS' ? 'child':'';

                        let traveler1 = new newTraveler(null, null, null, type1);
                        let traveler2 = new newTraveler(null, null, null, type2);

                        if(!keepDefaultTraveler) {
                            self.form.travelerInfos.push(traveler1);
                            self.form.travelerInfos.push(traveler2);
                        }
                    }

                    else if(self.form.travelerInfos && self.form.travelerInfos.length === 2) {

                        if(ibJS.planConfig && ibJS.planConfig.companyCode) {
                            let type = self.form.travelerInfos[1].travelerType === 'child' ? 'spouse' : 'child';
                            let traveler = new newTraveler(null, null, null, type);

                            if(traveler.travelerType === 'spouse') {
                                self.form.travelerInfos.splice(1,0,traveler);
                            }
                            else if(!keepDefaultTraveler) {
                                self.form.travelerInfos.push(traveler);
                            }

                        }

                        else {
                            let type = self.form.planId === 714 ? 'child' : '';
                            let traveler = new newTraveler(null, null, null, type);
                            if(!keepDefaultTraveler) {
                                self.form.travelerInfos.push(traveler);
                            }
                        }

                    }
                    if(storageKey !="application") {
                        convertDateByLang(self.form,window.globalLanguage.lang);
                    }

                    if(hccConfig.isStudentSecure() || hccConfig.isExchangeGuardGrp()) self.form.travelerInfos = self.form.travelerInfos.splice(0,1);
                }
        }

        if(responseBody && responseBody.data && responseBody.data.redirectTo) {
                try {
                    //IB-4081
                    window.stop();

                    if (ibJS.currentStep == 2) {
                        try {
                            let allPrice = 0;
                            const totalPrice = self.form.travelerInfos.reduce((accm, traveler) => accm+traveler.totalPrice, allPrice);
                            const fbEvent = {
                                "content_ids": [`${ibJS.application.planName}-${window.globalLanguage.lang}`],
                                "num_items": 1,
                                "content_type": "product",
                                "currency": "USD",
                                "value": totalPrice
                            };
                            fbq('track', 'CustomizeProduct', fbEvent);
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }catch(e){
                    console.error('error' , error);
                }
                window.location.href = responseBody.data.redirectTo;
        }
    }
},
        function (response) {
            if(response) {
                var responseBody = response.body;
                var errors = {};
                if(responseBody !== undefined && responseBody !== null) {
                    errors.serverErrors = responseBody.data;
                    self.$emit('backend500error', errors);
                    self.formSending = false;
                }

                $('html, body').animate({scrollTop: 0 }, 'fast');

                if(ibJS.currentStep==1){
                    if(self.form.travelerInfos.length==1){
                        var traveler1 = {
                                    age: null,
                                    tripCost: null,
                                    quotePrice:null,
                                    travelerType:self.form.planId==714?'spouse':''
                                };
                        var traveler2 = {
                                    age: null,
                                    tripCost: null,
                                    quotePrice:null,
                                    travelerType:self.form.planId==714?'child':''
                                };
                        self.form.travelerInfos.push(traveler1);
                        self.form.travelerInfos.push(traveler2);
                    }else if(self.form.travelerInfos.length==2){
                        var traveler = {
                            age: null,
                            tripCost: null,
                            quotePrice:null,
                            travelerType:self.form.planId==714?'child':''
                        };
                        self.form.travelerInfos.push(traveler);
                    }
                }
            }
        }
    );
}

function uploadExchangeGuardAgeGroups(apiUrl, data, headers) {
    var self = this;
    self.$emit('resubmit');
    // Spinner
    var spinTarget = self.$refs.btnspinner;
    var spinOpts = global.ibSpinOpts = {
      lines: 7 // The number of lines to draw
    , length: 21 // The length of each line
    , width: 6 // The line thickness
    , radius: 15 // The radius of the inner circle
    , scale: 0.3 // Scales overall size of the spinner
    , corners: 1 // Corner roundness (0..1)
    , color: '#fff' // #rgb or #rrggbb or array of colors
    , opacity: 0.45 // Opacity of the lines
    , rotate: 0 // The rotation offset
    , direction: 1 // 1: clockwise, -1: counterclockwise
    , speed: 1.4 // Rounds per second
    , trail: 60 // Afterglow percentage
    , fps: 20 // Frames per second when using setTimeout() as a fallback for CSS
    , zIndex: 2e9 // The z-index (defaults to 2000000000)
    , className: 'spinner' // The CSS class to assign to the spinner
    , top: '50%' // Top position relative to parent
    , left: '50%' // Left position relative to parent
    , shadow: false // Whether to render a shadow
    , hwaccel: true // Whether to use hardware acceleration
    , position: 'absolute' // Element positioning
    };

    var spinner = new IBSpinner(spinOpts).spin(spinTarget);
    self.formSending = true;
    self.$http.post(apiUrl, data, headers).then(function (response) {
        if(response && response.body){
            var responseBody = response.body;
            // Handle the error first
            if (responseBody && responseBody.status && responseBody.status === 'error') {
                var errors = {};

                if(responseBody.data) {
                    if (responseBody?.data?.globalErrors) {
                        errors.globalErrors = responseBody.data.globalErrors;
                    }
                    if (responseBody.data.fieldErrors) {
                        errors.fieldErrors = responseBody.data.fieldErrors;
                    }
                }
                self.form.travelers = null;
                self.fileName = '';
                document.querySelector("#upload").value = null;

                self.$emit('backenderrors', errors);
                self.formSending = false;

                $('html, body').animate({scrollTop: 0 }, 'fast');
            }
            if(responseBody && responseBody.data && responseBody.data.redirectTo) {
                try {
                    window.stop();
                }catch(e){}
                window.location.href = responseBody.data.redirectTo;
            }
        }
    });
}

function changeInSessionStorage(key,value){
    try {
        window.sessionStorage[key] = value;
    } catch(e){
        console.log("Session Storage is not accesible");
    }
};


function getDataFromSessionStorage(key){
    try{
        if(window.sessionStorage[key] == undefined || window.sessionStorage[key] == 'false'){
            return false
        }
        else{
            return true;
        }
    } catch(e) {
        return false;
    }
}


function postData(url,data) {
    var self = this;
    return self.$http.post(url,data);
}


function getDataFromSessionStorageForBlankFields(key) {
    try{
        if(window.sessionStorage[key] == undefined ){
            return '';
        }
        else if (window.sessionStorage[key] == 'false') {
            return false
        }
        else {
            return true;
        }
    } catch(e) {
        return '';
    }
}


function getItemSessionStorage(key){
    try{
        if (window.navigator.cookieEnabled) {
            if(window.sessionStorage[key] !== undefined) {
                return window.sessionStorage.getItem(key);
            } else {
                return undefined;
            }
        } else {
            console.log("Session Storage is not enabled.");
            return undefined;
        }
    } catch(e) {
        console.log("Session Storage is not enabled.");
        return undefined;
    }
}


function setItemSessionStorage(key,value) {
    try{
        if(value) {
            window.sessionStorage.setItem(key, value);
        }
    } catch(e) {
        console.log("Session Storage is not enabled.");
    }
}

/**
 * 
 * @param {string} key 
 * @returns {?string}
 */
function getItemLocalStorage(key) {
    if (!key) { console.warn("Key was not provided for local storage item"); return; }

    try {
        return localStorage.getItem(key);
    } catch (err) {
        console.log("Session Storage is not enabled.");
    }
}

/**
 * 
 * @param {string} key 
 * @param {string} value 
 * @returns {void}
 */
function setItemLocalStorage(key, value) {
    if (!key) return console.warn("Key was not provided for local storage item");

    try {
        localStorage.setItem(key, value);
    } catch (err) {
        console.log("Session Storage is not enabled.");
    }
}

function setCookieItem (key, value) {
    let today = new Date();
    today.setMonth(today.getMonth() + 1);
    let expMonth = today.toGMTString();
    try {
        document.cookie = key + '=' + value + '; expires= ' + expMonth + '; path=/; SameSite=Lax';
    } catch (err) {
        console.log('error occurred.');
    }
    // this.length++;
}

function getCookieItem (key) {
    var keyEQ = key + '=';
    var ca = document.cookie?.split(';');
    for (var i = 0, len = ca.length; i < len; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(keyEQ) === 0) return c.substring(keyEQ.length, c.length);
    }
    return null;
}


function goToFirstError(className='error-check') {
    const error = document.getElementsByClassName(className);
    const topDiff = window.bnJS ? 150 : 75;
    for(let i in error) {
        if(error[i] && error[i].style && error[i].style.display !== 'none' && $(error[i]).height() != 0) {
            if($(error[i]).parent().length) {
                $('html, body').animate({
                    scrollTop: $(error[i]).parent().offset().top-topDiff
                }, 750);
            }
            break;
        }
    }
}


function createExtensionService(apiUrl, dataString, storageKey) {
    var self = this;
    var data = JSON.parse(dataString);
    self.$emit('resubmit');
    // Spinner
    var spinTarget = self.$refs.btnspinner;
    var spinOpts = global.ibSpinOpts = {
      lines: 7 // The number of lines to draw
    , length: 21 // The length of each line
    , width: 6 // The line thickness
    , radius: 15 // The radius of the inner circle
    , scale: 0.3 // Scales overall size of the spinner
    , corners: 1 // Corner roundness (0..1)
    , color: '#fff' // #rgb or #rrggbb or array of colors
    , opacity: 0.45 // Opacity of the lines
    , rotate: 0 // The rotation offset
    , direction: 1 // 1: clockwise, -1: counterclockwise
    , speed: 1.4 // Rounds per second
    , trail: 60 // Afterglow percentage
    , fps: 20 // Frames per second when using setTimeout() as a fallback for CSS
    , zIndex: 2e9 // The z-index (defaults to 2000000000)
    , className: 'spinner' // The CSS class to assign to the spinner
    , top: '50%' // Top position relative to parent
    , left: '50%' // Left position relative to parent
    , shadow: false // Whether to render a shadow
    , hwaccel: true // Whether to use hardware acceleration
    , position: 'absolute' // Element positioning
    };

    var spinner = new IBSpinner(spinOpts).spin(spinTarget);
    self.formSending = true;
    if(window.globalLanguage.lang == 'zh'){
        let date = data.birthDate.split('-');
        data.birthDate = date[1] + '/' + date[2] + '/' + date[0];
    }else if(window.globalLanguage.lang == 'es'){
        let date = data.birthDate.split('/');
        data.birthDate = date[1] + '/' + date[0] + '/' + date[2];
    }
    var withLangUrl = apiUrl + "?lang=" + window.globalLanguage.lang;
    self.$http.post(withLangUrl, data)
    .then(function (response) {

    // Refactored in repsonse to the APP-241 fix
    if(response && response.body){

        var responseBody = response.body;

        // Parse as an Object if response is a string

        if (typeof responseBody === 'string') {
                responseBody = JSON.parse(responseBody);
            }

        // Handle the error first
        if (responseBody && responseBody.status && responseBody.status === 'error') {
                var errors = {};

                if(responseBody.data) {
                    if (responseBody?.data?.globalErrors) {
                        errors.globalErrors = responseBody.data.globalErrors;
                    }
                    if (responseBody.data.fieldErrors) {
                        errors.fieldErrors = responseBody.data.fieldErrors;
                    }
                }
                self.$emit('backenderrors', errors);
                self.formSending = false;

                $('html, body').animate({scrollTop: 0 }, 'fast');

                if(ibJS.currentStep==1){

                }
        }

        if(responseBody && responseBody.data && responseBody.data.redirectTo) {
                window.location.href = responseBody.data.redirectTo;
        }
    }
},
        function (response) {
            if(response) {
                var responseBody = response.body;
                var errors = {};
                if(responseBody !== undefined && responseBody !== null) {
                    errors.serverErrors = responseBody.data;
                    self.$emit('backend500error', errors);
                    self.formSending = false;

                    $('html, body').animate({scrollTop: 0 }, 'fast');
                }
                if(ibJS.currentStep==1){

                }
            }
        }
    );
}


 function hideValidationsWhileTyping(arr) {
    /* To use: 1) enter an array of input IDs that should be hidden on page
               2) add the ID name with '-check' to the parent of the validations as a class. (Usually with o-Valid-msg)*/
    try{
    arr.forEach( x => {
        const el = document.getElementById(x)
        const cl = document.getElementsByClassName(x+'-check');
        if(el && cl && cl.length) {
            el.addEventListener('keydown', (e) => {
                if(e && cl && cl[0]) {
                    e.target.id == document.activeElement.id ? cl[0].style.display = 'none' : cl[0].style.display = 'block'
                }
            });
            el.addEventListener('blur', () => {
                if(cl && cl[0]) {
                    cl[0].style.display = 'block'
                }
            } );
        }
    });
    }catch(e){}
}


class imgRules {
    constructor(destination, citizenship, planId, USandTerritories) {
        //To-Do Add Patriot Multi Trip America & Patriot Multi Trip Intl
        //this.rulePlans = [319,320,401,402,312,311,403,404,751,752];
        this.destination = destination;
        this.citizenship = citizenship;
        this.planId = planId;
        this.plansWithUS1andCRNonUS = [311,319,401,751];
        this.plansWithCRUS = [312,320,402,752];
        this.plansWithCRNonUS = [];
        this.USandTerritories = USandTerritories;
    }

    RuleUS1Applies() {
        if(this.plansWithUS1andCRNonUS.indexOf(this.planId) > -1 && this.USandTerritories.indexOf(this.destination) > -1){
            return true;
        }
        return false;
    }

    RuleCRUSApplies() {
        if(this.plansWithCRUS.indexOf(this.planId) > -1 &&
            this.citizenship !== '' &&
            this.destination !== '' &&
            this.USandTerritories.indexOf(this.citizenship) > -1 &&
            this.USandTerritories.indexOf(this.destination) > -1){
            return true;
        }
        return false;
    }
    RuleCRNonUSApplies() {
        if(this.plansWithUS1andCRNonUS.indexOf(this.planId) > -1 &&
           this.citizenship !== '' &&
           this.destination !== '' &&
           this.citizenship === this.destination &&
           this.USandTerritories.indexOf(this.destination) === -1){
            return true;
        }
        return false;
    }
    currentRule() {
        if(this.RuleUS1Applies()) {
            return 'RuleUS1';
        }
        else if(this.RuleCRNonUSApplies()) {
            return 'RuleCR-non-US';
        }
        else if(this.RuleCRUSApplies()) {
            return 'RuleCR-US';
        }
        return "";
    }
}


function initialReceiptView(x) {
    //Work around for window.print() in mounted function which unreliably displayed correct print view.
    let self = x;
    try {
        window.onafterprint = function() {
            self.printView=false;
        };
    }
    catch(e) {
        if(window.matchMedia) {
            window.matchMedia('screen').addListener(() => {
                if(self.printView && self.printed){
                    self.printView = false;
                }
                else {
                    self.printed = true;
                }
            });
        }
    }
    if(window.location.href.indexOf('?q=receipt') > -1 && window.print) {
        self.printView = true;
        Vue.nextTick(() => window.print());
    }
}


function removeKeySessionStorage(key) {
    try {
        window.sessionStorage.removeItem(key);
    } catch(e) {
        console.log("Enable Cache.");
    }
}

function setLocalStorage(key, value) {
    try {
        if(key && value) {
            window.localStorage.setItem(key, value)
        }
    } catch(e) {
        console.log('Please enable cookie.')
    }
}

function readFromLocalStorage(key) {
    try {
        if(key) {
            return window.localStorage.getItem(key);
        }
        return null;
    } catch (e) {
        return null;
    }
}

function deleteItemInLocalStorage(key) {
    try {
        if(key) {
            window.localStorage.removeItem(key);
        }
    } catch (e) {
        console.log('Please enable cookie.');
    }
}

function clearAllInLocalStorage(key) {
    try {
        window.localStorage.clear();
    } catch (e) {
        console.log('Please enable cookie.');
    }
}

function createCookie(name, value, days, domain) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
    } else {
        var expires = "";
    }
    try {
        document.cookie = name + "=" + value + expires + "; domain=" + domain + "; path=/; SameSite=Lax";
    } catch (error) {
        // Skip error;
        console.log('.');
    }
}

function eraseCookie(name, domain) {
    createCookie(name, "", -1, domain);
}

const spinOptsBtn = {
    lines: 7 // The number of lines to draw
    , length: 21 // The length of each line
    , width: 6 // The line thickness
    , radius: 15 // The radius of the inner circle
    , scale: 0.3 // Scales overall size of the spinner
    , corners: 1 // Corner roundness (0..1)
    , color: '#fff' // #rgb or #rrggbb or array of colors
    , opacity: 0.45 // Opacity of the lines
    , rotate: 0 // The rotation offset
    , direction: 1 // 1: clockwise, -1: counterclockwise
    , speed: 1.4 // Rounds per second
    , trail: 60 // Afterglow percentage
    , fps: 20 // Frames per second when using setTimeout() as a fallback for CSS
    , zIndex: 2e9 // The z-index (defaults to 2000000000)
    , className: 'spinner' // The CSS class to assign to the spinner
    , top: '50%' // Top position relative to parent
    , left: '50%' // Left position relative to parent
    , shadow: false // Whether to render a shadow
    , hwaccel: true // Whether to use hardware acceleration
    , position: 'absolute' // Element positioning
};

export {
    createUpdateQuoteService,
    initializeFormFromStorage,
    checkExpiredAndTriggerValidation,
    createUpdateApplicationService,
    changeInSessionStorage,
    getDataFromSessionStorage,
    postData,
    getDataFromSessionStorageForBlankFields,
    getItemSessionStorage,
    setItemSessionStorage,
    getCookieItem,
    setCookieItem,
    goToFirstError,
    createExtensionService,
    initialReceiptView,
    hideValidationsWhileTyping,
    imgRules,
    removeKeySessionStorage,
    setLocalStorage,
    readFromLocalStorage,
    deleteItemInLocalStorage,
    clearAllInLocalStorage,
    createCookie,
    eraseCookie,
    setItemLocalStorage,
    getItemLocalStorage,
    uploadExchangeGuardAgeGroups,
    spinOptsBtn
}