import { getItemSessionStorage, setItemSessionStorage } from "../../../helper-functions/services";

export default {
    data() {
        return {
            upsellModalTriggered: false,
            upsellSessionKey: "policymaxups",
            updatedPolicyMax: null,
            oldPolicyMaxVal: null,
            selectedYes: false
        }
    },
    methods: {
        considerUpsell () {
            const isUpsellTriggered = getItemSessionStorage(this.upsellSessionKey);
            const $policyMax = document.querySelector("#PolicyMaximum");
            const policyMax = this.canPolicyMaxIncreased($policyMax);
            const citizenship = ibJS.application.travelerInfos[0].citizenshipCountry || '';
            const homeCountry = ibJS.application.travelerInfos[0].homeCountry || '';
            const maxAgeLimit = ibJS.application.planId === 741 ? 90 : 80;
            const ageMatch = ibJS.application.travelerInfos.filter(trvl => trvl.age > 49 && trvl.age < maxAgeLimit);
            const visitorUSA = ibJS.planConfig.visitorUSA;
            if (policyMax && citizenship != 'USA' && homeCountry != 'USA' && ageMatch.length && !this.upsellModalTriggered && visitorUSA && !isUpsellTriggered) {
                this.showUpsellModal();
            } else {
                this.upsellModalTriggered = true;
            }
        },
        showUpsellModal () {
            const $policyMax = document.querySelector("#PolicyMaximum");
            const ageModalVariant = window.variantType || "agepolicyupsell";
            global.eventHub.$on('show-modal-rendered', (rendered)=>{
                const $bodyContainer = document.querySelector(".c-Modal__container");
                let $bodyContent = document.querySelector(".c-Modal__body");
                let $header = $bodyContent.querySelector(".header-replace");
                if (rendered && $header) {
                    let printReplaceTitle = "";
                    let $modalHeader = document.querySelector(".c-Modal__header");
                    let $close = document.querySelector(".c-Modal__close");
                    try {
                        if ($bodyContent) {
                            if ($header && ageModalVariant !== 'agepolicyupsell-v1') {
                                printReplaceTitle = $header.innerText;
                                $header.remove();
                            }
                        }
                        if ($modalHeader) $modalHeader.classList.add("c-Modal__header--share");
                        if ($close) $close.classList.add("u-Hidden");

                        if (ageModalVariant === 'agepolicyupsell-v1') {
                            $bodyContainer.classList.add('c-ibApp__Modal-img');
                            $modalHeader.remove();
                        }
                    } catch (error) {
                        console.error('Modal Parser Error', error);
                    }
                    if (printReplaceTitle) {
                        const $printWrap = document.querySelector(".c-Modal__print-wrap");
                        if ($printWrap) {
                            $printWrap.innerHTML = "";
                            const $iconSvg = $header.querySelector('svg');
                            if ($iconSvg) $printWrap.append($iconSvg);
                            const $h3 = document.createElement("h3");
                            $h3.classList.add("c-Modal__heading");
                            $h3.classList.add("u-Ma-b-0");
                            $h3.classList.add("c-Modal__heading--quote");
                            $h3.innerText = printReplaceTitle;
                            $printWrap.append($h3);
                            $printWrap.parentElement.classList.add('c-Modal__header--video');
                            // $printWrap.parentElement.classList.add('u-Pa-b-0');
                        }
                    }
                    const $upsellModalYes = document.querySelector('#upsellModalYes');
                    const $upsellModalNo = document.querySelector('#upsellModalNo');
                    const $upsellChat = document.querySelector('#upsellChat');
                    const $callback = document.querySelector('#upsellTalk');
                    if ($upsellModalYes) {
                        $upsellModalYes.addEventListener('click', e => {
                            this.hideModal();
                            if ($policyMax) $policyMax.classList.add("policymax_yes");
                            this.$nextTick(() => {
                                $policyMax.focus();
                            });
                            this.setSessionKey();
                            this.oldPolicyMaxVal = this.form.policyMaximum;
                            this.selectedYes = true;
                        })
                    }
                    if ($upsellModalNo) {
                        $upsellModalNo.addEventListener('click', e => {
                            this.hideModal();
                            this.setSessionKey();
                        })
                    }
                    if ($upsellChat) {
                        $upsellChat.addEventListener('click', e => {
                            this.hideModal();
                            this.$nextTick(() => {
                                Genesys("command", "Messenger.open", {},
                                    function(o){},  // if resolved
                                    function(o){    // if rejected
                                        Genesys("command", "Messenger.close");
                                    }
                                );
                            });
                        })
                    }
                    if ($callback) {
                        $callback.addEventListener('click', e => {
                            this.hideModal();
                            this.$nextTick(() => {
                                GlobalVue.showGoAwayModal();
                            });
                        });
                    }

                    global.eventHub.$off('show-modal-rendered');
                }
            });
            this.showModal(`/guide/terms?section=${ageModalVariant}`);
            this.$nextTick(() => {
                this.upsellModalTriggered = true;
            });
        },
        canPolicyMaxIncreased ($policyMax) {
            let incred = false;
            const plcyMaxChosen = +this.form.policyMaximum;
            if (this.plan.policyMaximums?.length && [25000,50000,60000].indexOf(plcyMaxChosen) > -1 && $policyMax) {
                const otherOptions = this.plan.policyMaximums.filter(plm => {
                    return (plm > 25000 && plm > 50000 && plm > 60000);
                });
                incred = otherOptions.length ? true : false;
            }
            return incred;
        },
        setSessionKey () {
            setItemSessionStorage(this.upsellSessionKey, 'set');
        },
        tagManagerUpdate () {
            if (this.form.policyMaximum != this.oldPolicyMaxVal) {
                window.dataLayer.push({
                    'event': 'app_policymax_upsell',
                    'old_max': this.oldPolicyMaxVal,
                    'new_max': this.form.policyMaximum,
                    'app_id': ibJS.application.fullAppId
                });
            }
        }
    }
}