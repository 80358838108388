<template>
    <article class="c-Plan"
        :class="{'is-Minimized': minimized, 'is-Bestseller': plan.planDetails.config.bestseller, 'has-Video': plan.planDetails.videoURLInsubuy != null && plan.planDetails.videoURLAgents != null, 'is-No-Price': !showPrice, 'is-Trip-Plan' : section==='trip', 'is-Flight-Plan': section==='flight', 'is-Travelout': isTravelOutsideSchengen}">
        
        <!-- // TOP BAR -->
        <div class="c-Plan__top-bar" :style="{backgroundColor: plan.planDetails.config.planColor}">
            <div v-if="plan.planDetails.config.bestseller" class="c-Plan__bestseller">
                <span class="c-Plan__bestseller-txt">{{qResultsLang.bestSeller}}</span>
            </div>

            <div class="c-Plan__compare" v-if="showCompare">
                <!-- <label class="c-Plan__compare-txt">
                    {{ qResultsLang.compare }}
                    <input type="checkbox" class="c-Plan__compare-check" v-model="compare" v-on:change="toggleCompare">
                </label> -->
                <v-checkbox
                    :id="'compare-plan-' + plan.planId"
                    :plan-id="plan.planId"
                    label-class="c-Plan__compare-txt"
                    :value="plan.compare"
                    @change="toggleCompare">
                    <template v-slot:prefix>{{qResultsLang.compare}} &nbsp; </template>
                </v-checkbox>
            </div>
        </div><!-- // TOP BAR -->

        <!-- // CONTENT WRAP -->
        <div class="c-Plan__content-wrap">

            <!-- // TITLE BAR -->
            <div class="c-Plan__title-bar">
                <div class="c-Plan__name-col">
                    <h3 class="c-Plan__plan-name">
                    <span v-html="plan.planDetails.config.displayNameWSym"></span>
                    <template v-if="plan.planDetails.videoURLInsubuy && plan.planDetails.videoURLInsubuy.trim() !== '' && plan.planDetails.videoURLAgents
                     && plan.planDetails.videoURLAgents.trim() !== '' && affiliateVideo && isCountrynotBlocked" >
                        <span class="o-Youtube-video-icon" :data-plan-id="plan.planId" :data-plan-type="planTypeModal" @click.prevent="showModal('/showplanvideo?planId='+plan.planId+'&planType='+planTypeModal+'&section=video&lang='+currentLang)"></span>
                    </template>
                    </h3>
                    
                    <p class="c-Plan__underwriter">
                        <strong>{{ qResultsLang.underWriter }}</strong>
                        {{plan.planDetails.config.carrier}}
                    </p>
                </div>

                <div class="c-Plan__price-col" v-if="showPrice">
                    <p class="c-Plan__total-for c-Plan__total-for--overflow">
                        <span v-if="section !== 'expatriatehealth'">{{ qResultsLang.totalFor }}</span>
                        <span v-if="section==='travelmedical' || section==='studentmedical' || section==='grouptravelmedical'">{{plan.quotedDuration}}</span>
                        <span v-if="section==='trip' || section==='flight'"> {{plan.tripLength}} {{qResultsLang.days}}</span>
                        <span v-if="section==='multitrip'">{{ qResultsLang.TwelveMonthTerm }}</span>
                        <span v-if="section==='expatriatehealth'"></span>
                        <span v-if="section==='expatriatehealth'">{{frequency}}</span>
                    </p>
                    <span class="c-Plan__plan-price" v-if="priceIsSet && showPriceBreakdown">{{ totalPrice | currency}} {{qResultsLang.USD}}</span>
                    <span class="c-Plan__plan-price" v-if="priceIsSet && !showPriceBreakdown">{{ '10' | currency}}  {{qResultsLang.USD}}</span>
                </div>
                <div class="c-Plan__price-col c-Plan__click-for-price-col" v-else-if="plan.onlyPaperApp && !showPrice">
                    <div class="c-Plan__plan-price c-Plan__click-for-price">
                        <span v-html="qResultsLang.clickPaperAppForPurchaseText"></span>
                    </div>
                </div>
                <div class="c-Plan__price-col c-Plan__click-for-price-col" v-else>
                    <div class="c-Plan__plan-price c-Plan__click-for-price">
                        <span v-html="qResultsLang.clickPurchaseForPriceText"></span>
                    </div>
                </div>

                <div class="c-Plan__purchase-col" v-if="!plan.onlyPaperApp && showPrice">
                    <button 
                        class="o-Btn o-Btn--small c-Plan__purchase-btn c-Plan__purchase-btn--buy" 
                        :class="{'c-Plan__purchase-btn--disabled': deductibleNotAvailable, 'c-Plan__purchase-btn--fixed': primarySection == 'visitorUSA' && plan.planDetails.config.planType == 1, 'c-Plan__purchase-btn--comp': primarySection == 'visitorUSA' && plan.planDetails.config.planType == 2}" 
                        :disabled="deductibleNotAvailable" 
                        :data-plan-name="plan.planDetails.config.displayNamePlain" 
                        @click.prevent="goToApp"
                    >
                        <span ref="purchaseButton" v-show="loading" class="c-Plan__purchase-spinner" v-cloak></span>
                        {{ !loading ? qResultsLang.purchase : '' }}
                    </button>
                </div>
                <div class="c-Plan__purchase-col c-Plan__no-price-btn" v-else-if="!plan.onlyPaperApp && !showPrice" :disabled="deductibleNotAvailable" :class="{'c-Plan__purchase-btn--disabled': deductibleNotAvailable}">
                    <button 
                        class="o-Btn o-Btn--small c-Plan__purchase-btn c-Plan__purchase-btn--buy" 
                        :class="{'c-Plan__purchase-btn--fixed': primarySection == 'visitorUSA' && plan.planDetails.config.planType == 1, 'c-Plan__purchase-btn--comp': primarySection == 'visitorUSA' && plan.planDetails.config.planType == 2}" 
                        :data-plan-name="plan.planDetails.config.displayNamePlain" 
                        @click.prevent="goToApp"
                    >
                        <span ref="purchaseButton" v-show="loading" class="c-Plan__purchase-spinner" v-cloak></span>
                        {{ !loading ? qResultsLang.purchase : '' }}
                    </button>
                </div>
                <div class="c-Plan__purchase-col" v-else-if="plan.onlyPaperApp && showPrice && plan.planDetails.config.companyCode !== 'IMG'">
                    <a 
                        :href="plan.paperAppLink" 
                        :data-plan-name="plan.planDetails.config.displayNamePlain"
                        class="o-Btn o-Btn--small c-Plan__purchase-btn c-Plan__purchase-btn--view-all c-Plan__purchase-btn--buy" 
                        :class="{'c-Plan__purchase-btn--fixed': primarySection == 'visitorUSA' && plan.planDetails.config.planType == 1, 'c-Plan__purchase-btn--comp': primarySection == 'visitorUSA' && plan.planDetails.config.planType == 2}" 
                        @click="loadingPurchase"
                    >
                        {{ !loading ? qResultsLang.paperApp : '' }}
                        <span ref="purchaseButton" v-show="loading" class="c-Plan__purchase-spinner" v-cloak></span>
                    </a>
                </div>
                <div class="c-Plan__purchase-col" v-else-if="plan.onlyPaperApp && showPrice && plan.planDetails.config.companyCode === 'IMG'">
                    <a 
                        :href="'javascript:void(0)'" 
                        :data-plan-name="plan.planDetails.config.displayNamePlain"
                        class="o-Btn o-Btn--small c-Plan__purchase-btn c-Plan__purchase-btn--view-all c-Plan__purchase-btn--buy"
                        :class="{'c-Plan__purchase-btn--fixed': primarySection == 'visitorUSA' && plan.planDetails.config.planType == 1, 'c-Plan__purchase-btn--comp': primarySection == 'visitorUSA' && plan.planDetails.config.planType == 2}" 
                        @click="openConactModal"
                    >
                        <span v-html="qResultsLang.callUsToPurchase"></span>
                    </a>
                </div>
                <div class="c-Plan__purchase-col c-Plan__no-price-btn" v-else>
                    <a 
                        :href="plan.paperAppLink" 
                        :data-plan-name="plan.planDetails.config.displayNamePlain"
                        class="o-Btn o-Btn--small c-Plan__purchase-btn c-Plan__purchase-btn--view-all c-Plan__purchase-btn--buy" 
                        :class="{'c-Plan__purchase-btn--fixed': primarySection == 'visitorUSA' && plan.planDetails.config.planType == 1, 'c-Plan__purchase-btn--comp': primarySection == 'visitorUSA' && plan.planDetails.config.planType == 2}" 
                        @click="loadingPurchase"
                    >
                        {{ !loading ? qResultsLang.paperApp : '' }}
                        <span ref="purchaseButton" v-show="loading" class="c-Plan__purchase-spinner" v-cloak></span> 
                    </a>
                </div>
            </div><!-- // TITLE BAR -->

            <!-- // QUICK VIEW -->
            <div class="c-Plan__content c-Plan__content--quick">
                
                <!-- // QUICK CONTENT LEFT -->
                
                <div class="c-Plan__content-left">

                    <!-- // Overview -->
                    <div class="c-Plan__content-sub">
                        <h3 class="c-Plan__content-heading c-Plan__content-icon c-Plan__content-icon--overview">
                            {{qResultsLang.overview}}
                        </h3>
                        <plan-summary
                            :content="plan.planBlockDetails.QuickSummary"
                            @showmodal="showModal" 
                            @showMiddlePageModal="showMiddlePageModal"
                        >
                        </plan-summary>
                    </div>

                    <!-- // Medical Coverage -->
                    <div class="c-Plan__content-sub" v-if="isTravelOutsideSchengen && section!=='multitrip' && section!=='expatriate' && section!=='missionary' && section!=='marine' && section!=='grouptravelmedical'">
                        <h3 class="c-Plan__content-heading c-Plan__content-icon c-Plan__content-icon--medical">
                            {{qResultsLang.medicalCoverage}}
                        </h3>
                        <div class="c-Plan-details__text">
                            <a href @click.prevent="showModal('/guide/terms?section=medical')">
                                <span v-if="plan.planDetails.config.planType == 2">
                                    {{qResultsLang.policyMaximum}}
                                </span>
                                <span v-else-if="plan.planDetails.config.planType == 1">
                                    {{qResultsLang.scheduleBenifits}}
                                </span>
                            </a>
                        </div>
                    </div>

                    <!-- // Direct Billing -->
                    <div class="c-Plan__content-sub" v-if="plan.planBlockDetails.directBilling != null">
                        <h3 class="c-Plan__content-heading c-Plan__content-icon c-Plan__content-icon--billing">{{qResultsLang.directBilling}}</h3>
                        <plan-summary
                            class="c-Plan-details__text"
                            :content="plan.planBlockDetails.directBilling.split(':')[1].replace('o-Question', 'o-Information')" 
                            @showmodal="showModal" 
                            @showMiddlePageModal="showMiddlePageModal"
                        >
                        </plan-summary>
                    </div>

                    <!-- // Pre-Existing -->
                    <template v-for="(i, index) in plan.planBlockDetails.listOfLinks">
                        <div class="c-Plan__content-sub" :key="index" v-if="i.type == 'Pre-ExistingConditionsType'">
                            <h3 v-if="plan.planDetails.config.acuteOnsetPreExisting" class="c-Plan__content-heading c-Plan__content-icon c-Plan__content-icon--preext">
                                {{plan.planId != 755 ? qResultsLang.acuteOnsetPreCondition : qResultsLang.preExistingCondition}}
                            </h3>
                            <h3 v-else-if="!plan.planDetails.config.acuteOnsetPreExisting" class="c-Plan__content-heading c-Plan__content-icon c-Plan__content-icon--preext">
                                <span v-html="i.text.split(':')[0]"></span>
                            </h3>
                            <div class="c-Plan-details__text">
                                <span v-if="plan.planDetails.config.acuteOnsetPreExisting">
                                    {{i.text | capitalize}}&nbsp;<a :href="i.url" target="_blank">{{qResultsLang.learnMore}}</a>
                                </span>
                                
                                <span v-else-if="!plan.planDetails.config.acuteOnsetPreExisting">
                                    <span v-html="i.text.split(':')[1]"></span>&nbsp;<a :href="i.url" target="_blank">{{qResultsLang.learnMore}}</a>
                                </span>

                                <a v-if="i.hasHelp" href="javascript:void(0);" 
                                   @click.prevent="showModal('/guide/terms?section='+i.modalKey+'&lang='+currentLang)" 
                                   class="o-Information"
                                ></a>
                            </div>
                        </div>
                        <div class="c-Plan__content-sub" :key="index" v-if="i.type == 'Pre-ExistingConditionsTypeTextOnly'">
                            <h3 v-if="plan.planDetails.config.acuteOnsetPreExisting" class="c-Plan__content-heading c-Plan__content-icon c-Plan__content-icon--preext">
                                {{qResultsLang.acuteOnsetPreCondition}}
                            </h3>
                            <div v-if="!plan.planDetails.config.acuteOnsetPreExisting || showAcuteOnsetLink" class="c-Plan-details__text">
                                <span v-html="i.text"></span>
                                <a v-if="i.hasHelp" href="javascript:void(0);" 
                                    @click.prevent="showModal('/guide/terms?section='+i.modalKey+'&lang='+currentLang)"
                                    class="o-Information"
                                ></a>
                            </div>
                        </div>
                    </template>

                    <!-- // Alerts/Remarks -->
                    <template  v-if="plan.planRemarks">
                        <div class="c-Plan__content-sub" :key="i+planRemark+i" v-for="(planRemark, i) in plan.planRemarks">
                            <p class="c-Plan__content-text c-Plan__content-icon c-Plan__content-icon--alert">
                                <span>{{planRemark}}</span>
                            </p>
                        </div>
                    </template>
                    
                    <div class="c-Plan__content-sub" v-if="deductibleNotAvailable && qResultsLang.deductibleNotAvailable">
                        <p class="c-Plan__content-text c-Plan__content-icon c-Plan__content-icon--alert">
                            <span>{{qResultsLang.deductibleNotAvailable}}</span>
                        </p>
                    </div>
                </div>

                <!-- // QUICK CONTENT RIGHT -->

                <div class="c-Plan__content-right">
                    <div class="c-Plan__pricing-options"> <!--- v-if="showPrice && section==='travelmedical'" --->
                        
                        <!--// PRICE OPTIONS -->

                        <div class="c-Plan__pricing-options-wrap">

                            <!--// Travel Medical -->
                            <plan-block-price-options-v2 
                                v-if="showPrice && section==='travelmedical' && primarySection != 'missionaryTravel' && primarySection != 'newImmigrants'"
                                :plan="plan"
                                :increment-deductible="incrementDeductible"
                                :decrement-deductible="decrementDeductible"
                                :deductible-value="deductible"
                                :coinsurance-value="coInsuranceFromLang" 
                                :piu-covid="piuCovid"
                                @total-price-e="setPrice"
                                @show-modal="showModal"
                                @reset-deductible="resetDeductible"
                                @selected-deductible="setDeductible"
                                @selected-coinsurance="setCoInsurance"
                                @update-compare="updateCompare"
                                @set-price-array="setPriceArray"
                                @set-plan-block="setPlanBlock"
                            >
                            </plan-block-price-options-v2>

                            <plan-block-price-options 
                                v-if="showPrice && (primarySection === 'missionaryTravel' || primarySection === 'newImmigrants')"
                                :plan="plan"
                                :increment-deductible="incrementDeductible"
                                :decrement-deductible="decrementDeductible"
                                :deductible-value="deductible"
                                :coinsurance-value="coInsuranceFromLang" 
                                :piu-covid="piuCovid"
                                @total-price-e="setPrice"
                                @show-modal="showModal"
                                @reset-deductible="resetDeductible"
                                @selected-deductible="setDeductible"
                                @selected-coinsurance="setCoInsurance"
                                @update-compare="updateCompare"
                                @set-price-array="setPriceArray"
                                @set-plan-block="setPlanBlock"
                            >
                            </plan-block-price-options>

                            <!--// Student Medical -->
                            <plan-block-price-options-student v-if="showPrice && section==='studentmedical'"
                                :plan="plan"
                                @total-price-e="setPrice"
                                @policy-maximum-e="setPolicyMax"
                                @show-modal="showModal"
                                @selected-deductible="setDeductible"
                                @coinsurance-student="setCoinsuranceOption"
                                @update-compare="updateCompare"
                                @set-price-array="setPriceArray"
                                @set-plan-block="setPlanBlock"
                            >
                            </plan-block-price-options-student>

                            <!--// Flight -->
                            <plan-block-price-options-flight v-if="showPrice && section==='flight'"
                                :plan="plan"
                                @total-price-e="setPrice"
                                @show-modal="showModal"
                                @set-plan-block="setPlanBlock"
                            >
                            </plan-block-price-options-flight>

                            <!--// MultiTrip -->
                            <plan-block-price-options-multitrip v-if="showPrice && section==='multitrip'"
                                :plan="plan"
                                @update-compare="updateCompare"
                                @total-price-e="setPrice"
                                @show-modal="showModal"
                                @set-price-array="setPriceArray"
                                @set-plan-block="setPlanBlock"
                            >
                            </plan-block-price-options-multitrip>

                            <!-- Trip -->
                            <plan-block-price-options-trip
                                v-if="showPrice && section==='trip'"
                                :plan="plan" 
                                :section="section" 
                                :cancel-anyreason="cancelAnyreason" 
                                @show-modal="showModal"
                                @total-price-e="setPrice"
                                @set-plan-block="setPlanBlock"
                            >
                            </plan-block-price-options-trip>

                            <!--// Expatriate -->
                            <plan-block-price-options-expatriate v-if="showPrice && section==='expatriatehealth'"
                                :plan="plan"
                                @update-compare="updateCompare"
                                @total-price-e="setPrice"
                                @policy-maximum-e="setPolicyMax"
                                @show-modal="showModal"
                                @selected-frequency="setFrequency"
                                @set-price-array="setPriceArray"
                                @set-plan-block="setPlanBlock"
                            >
                            </plan-block-price-options-expatriate>

                            <!-- Group -->
                            <plan-block-price-options-group v-if="showPrice && section==='grouptravelmedical'"
                                :plan="plan"
                                :increment-deductible="incrementDeductible"
                                :decrement-deductible="decrementDeductible"
                                :deductible-value="deductible"
                                :coinsurance-value="coInsuranceFromLang"
                                @update-compare="updateCompare"
                                @total-price-e="setPrice"
                                @show-modal="showModal"
                                @reset-deductible="resetDeductible"
                                @selected-deductible="setDeductible"
                                @selected-coinsurance="setCoInsurance"
                                @set-price-array="setPriceArray"
                                @set-plan-block="setPlanBlock"
                            >
                            </plan-block-price-options-group>

                            <!-- // PRICE FEATURES -->

                            <plan-block-price-features
                                :plan="plan"
                                :is-coverage-area-usa="isCoverageAreaUsa"
                                :narrow-covid-coverage="$parent.narrow.covidCoverage"
                                :piu-covid-default="ssCriteria.piuCovidCheck"
                                @show-modal="showModal"
                                @set-plan-block="setPlanBlock" 
                                @piu-covid="(val) => piuCovid = val"
                            >
                            </plan-block-price-features>

                        </div>
                    </div>                  
                </div>

            </div><!-- // QUICK VIEW -->

            <!-- // EXPANDED VIEW -->
            <div class="c-Plan__content c-Plan__content--expanded">

                <div class="c-Plan__content-left" :class="{'c-Plan__content-full': !showPriceBreakdown}">
                    
                    <!-- // PLAN DETAILS -->

                    <h3 class="c-Plan__content-heading c-Plan__content-icon c-Plan__content-icon--plan-details">
                        {{qResultsLang.planDetails}}
                        <span class="c-Plan__heading-action" @click="toggleMobileTab('details')" :class="isPlanDetailsVisible ? 'c-Plan__heading-action--active' : ''"></span>
                    </h3>

                    <div class="c-Plan__details-links c-Plan-details__links-wrap" :class="isPlanDetailsVisible ? 'c-Plan__details-links--active' : ''">
                        <ul class="o-List-bare o-List-M c-Plan-details__list">
                            <template v-for="(i, index) in plan.planBlockDetails.listOfLinks">
                                <li v-if="i.type == 'Normal'"
                                    :key="index"
                                    class="o-List-M__item c-Plan-details__item"
                                >
                                    <a :href="i.url" @click="showMiddlePageModal" :data-label="i.label"
                                        class="o-List-M__link o-Icon-bullet o-Icon-bullet__carret-gray o-Icon-bullet--swap-lrg  c-Plan-details__link"
                                    >
                                        {{i.text}}
                                    </a>
                                </li>
                                <li v-if="i.type == 'TermsModal'"
                                    :key="index"
                                    class="o-List-M__item c-Plan-details__item"
                                >
                                    <a href
                                        @click.prevent="showModal('/guide/terms?section='+i.modalKey+'&lang='+currentLang + (optionalCoveragesLabel == i.text? '&termsModal=true' : '' ))"
                                        class="o-List-M__link o-Icon-bullet o-Icon-bullet__carret-gray o-Icon-bullet--swap-lrg  c-Plan-details__link"
                                    >
                                        {{i.text}}
                                    </a>
                                </li>
                                <li v-if="i.type == 'Geo_RestrictModal'"
                                    :key="index"
                                    class="o-List-M__item c-Plan-details__item"
                                >
                                    <a href @click.prevent="showModal('/showgeorestrictions?planId='+plan.planId+'&planType='+planTypeModal+'&lang='+currentLang)"
                                        class="o-List-M__link o-Icon-bullet o-Icon-bullet__carret-gray o-Icon-bullet--swap-lrg  c-Plan-details__link"
                                    >
                                        {{i.text}}
                                    </a>
                                </li>
                                <li v-if="i.type == 'CoverageAreaLink'"
                                    :key="index"
                                    class="o-List-M__item c-Plan-details__item"
                                >
                                    <span class="o-List-M__link o-Icon-bullet o-Icon-bullet__carret-gray o-Icon-bullet--swap-lrg  c-Plan-details__link c-Plan-details__link c-Plan-details__link--text">
                                        {{i.url}}
                                    </span>
                                </li>
                                <li v-if="i.type == 'TextOnly'"
                                    :key="index" 
                                    class="o-List-M__item c-Plan-details__item">
                                    <span class="o-List-M__link o-Icon-bullet o-Icon-bullet__carret-gray o-Icon-bullet--swap-lrg  c-Plan-details__link c-Plan-details__link--text" v-html="i.text">
                                    </span>
                                </li>
                                <li v-if="i.type == 'PaperAppType'"
                                    :key="index" 
                                    class="o-List-M__item c-Plan-details__item">
                                    <a :href="i.url+i.affiliateParams"
                                        class="o-List-M__link o-Icon-bullet o-Icon-bullet__carret-gray o-Icon-bullet--swap-lrg  c-Plan-details__link"
                                    >
                                        {{i.text}}
                                    </a>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>

                <div class="c-Plan__content-right" v-show="showPriceBreakdown">

                    <!--// PRICE BREAKDOWN -->

                    <h3 class="c-Plan__content-heading c-Plan__content-icon c-Plan__content-icon--price-breakdown">
                        {{qResultsLang.priceBreakdown}}
                        <span class="c-Plan__heading-action" @click.prevent="toggleMobileTab('price')" :class="isPriceBreakdownVisible ? 'c-Plan__heading-action--active' : ''"></span>
                    </h3>

                    <div 
                        class="c-Plan__pricing"  
                        :class="isPriceBreakdownVisible ? 'c-Plan__pricing--active' : ''" 
                        v-if="showPrice && ['travelmedical', 'studentmedical', 'grouptravelmedical', 'expatriatehealth', 'multitrip'].includes(section)">
                        <price-breakdown 
                            :plan="plan"
                            :price-list="priceArray"
                            :total-price="totalPrice"
                            :global-section="section"
                            @show-modal="showModal"
                        />
                    </div>

                    <!-- BELOW NOT DONE -->

                    <div class="c-Plan__pricing c-Plan__no-pricing" v-if="!showPrice && (section==='travelmedical' || section==='studentmedical' || section==='expatraitehealth')">

                        <div class="c-Plan__pricing-wrap c-Plan__no-pricing-wrap">
                            <div class="c-Plan__no-price-msg" v-if="!plan.onlyPaperApp">
                                <span>{{ qResultsLang.clickPurchaseForQuoteText }}</span>
                                <a :href="plan.planDetails.purchaseLink" :data-plan-name="plan.planDetails.config.displayNamePlain"
                                class="o-Btn o-Btn--small c-Plan__purchase-btn c-Plan__purchase-btn--buy">
                                {{ qResultsLang.purchase }}
                            </a>
                            </div>
                            <div class="c-Plan__no-price-msg" v-else>
                                <span>{{qResultsLang.clickPaperAppToCompleteText}}</span>
                                <a :href="plan.paperAppLink" :data-plan-name="plan.planDetails.config.displayNamePlain"
                                    class="o-Btn o-Btn--small c-Plan__purchase-btn c-Plan__purchase-btn--buy">
                                    {{ qResultsLang.paperApp }}
                                </a>
                            </div>
                        </div>
                    </div>

                </div>

            </div><!-- // EXPANDED VIEW -->
        </div><!-- // CONTENT WRAP -->

        <!-- // VIEW MORE/LESS BAR -->
        <div class="c-Plan__view-bar" v-if="section !== 'trip' && section !== 'flight'">
            <a href="javascript:void(0)" :id="'view-more-plan-' + plan.planId" v-show="minimized" @click="minimize" class="c-Plan__view-bar-link c-Plan__view-bar-link--green">{{qResultsLang.viewMore}}</a>
            <a href="javascript:void(0)" :id="'view-less-plan-' + plan.planId" v-show="!minimized" @click="minimize" class="c-Plan__view-bar-link c-Plan__view-bar-link--red">{{qResultsLang.viewLess}}</a>            
        </div><!-- // VIEW MORE/LESS BAR -->

    </article>
</template>

<script>
// PRICE OPTIONS
// import planBlockPriceOptions from '../../view-specific/quote-results/v2/plan-block-price-options.vue';
// import planBlockPriceOptionsStudent from '../../view-specific/quote-results/v2/plan-block-price-options-student.vue';
// import planBlockPriceOptionsFlight from '../../view-specific/quote-results/v2/plan-block-price-options-flight.vue';
// import planBlockPriceOptionsMultiTrip from '../../view-specific/quote-results/v2/plan-block-price-options-multitrip.vue';
// import planBlockPriceOptionsExpatriate from '../../view-specific/quote-results/v2/plan-block-price-options-expatriate.vue';
// import planBlockPriceOptionsGroup from '../../view-specific/quote-results/v2/plan-block-price-options-group.vue';
// import planBlockPriceOptionsTrip from '../../view-specific/quote-results/v2/plan-block-price-options-trip.vue';
// import planBlockPriceOptionsV2 from '../../view-specific/quote-results/v2/plan-block-price-options-v2.vue';

// PRICE BREAKDOWN
// import pbPriceBreakdown from '../../view-specific/quote-results/v2/pb-price-breakdown.vue';

// PRICE FEATURES
import planBlockPriceFeatures from '../../view-specific/quote-results/v2/plan-block-price-features.vue';


import planSummary from '../../view-specific/quote-results/v2/plan-summary-v2';
import { createUpdateApplicationService, setItemSessionStorage } from '../../helper-functions/services';
import dayjs from 'dayjs';
import { convertDatei18n_es } from '../../helper-functions/helper-functions';
import { travelInsuredConfig } from '../../helper-functions/app-configs';
import Checkbox from '../../components/components.checkbox.vue';
import {ibSpinOpts} from '../../helper-functions/applicationCommonValues';
import PlanIds from '../../helper-functions/all-planIds';
import IMG from '../../helper-functions/companies/img-planids';
import { showMiddlePageModal } from '../../helper-functions/plan-details-functions';
const imgPlans = new IMG();

export default {
    props: ['index','plan', 'trip-length', 'all-minimized', 'all-compared','increment-deductible','decrement-deductible','ss-criteria','list', 'cancel-anyreason', 'is-coverage-area-usa', 'show-compare'],
    components: {
        // PRICE OPTIONS
        'plan-block-price-options-v2': () => import ('../../view-specific/quote-results/v2/plan-block-price-options-v2.vue'),
        'plan-block-price-options': () => import ('../../view-specific/quote-results/v2/plan-block-price-options.vue'),
        'plan-block-price-options-student': () => import ('../../view-specific/quote-results/v2/plan-block-price-options-student.vue'),
        'plan-block-price-options-flight': () => import ('../../view-specific/quote-results/v2/plan-block-price-options-flight.vue'),
        'plan-block-price-options-multitrip': () => import ('../../view-specific/quote-results/v2/plan-block-price-options-multitrip.vue'),
        'plan-block-price-options-expatriate': () => import ('../../view-specific/quote-results/v2/plan-block-price-options-expatriate.vue'),
        'plan-block-price-options-group': () => import ('../../view-specific/quote-results/v2/plan-block-price-options-group.vue'),
        'plan-block-price-options-trip': () => import ('../../view-specific/quote-results/v2/plan-block-price-options-trip.vue'),
        // 'plan-block-price-options' : planBlockPriceOptions,
        // 'plan-block-price-options-student' : planBlockPriceOptionsStudent,
        // 'plan-block-price-options-flight' : planBlockPriceOptionsFlight,
        // 'plan-block-price-options-multitrip' : planBlockPriceOptionsMultiTrip,
        // 'plan-block-price-options-expatriate' : planBlockPriceOptionsExpatriate,
        // 'plan-block-price-options-group' : planBlockPriceOptionsGroup,
        // 'plan-block-price-options-trip' : planBlockPriceOptionsTrip,
        
        'plan-block-price-features' : planBlockPriceFeatures,

        // 'price-breakdown': pbPriceBreakdown,
        'price-breakdown': () => import ('../../view-specific/quote-results/v2/pb-price-breakdown.vue'),

        'plan-summary' : planSummary,
        'v-checkbox': Checkbox
    },
    data: function () {
        return {
            qResultsLang: window.qResultsLang || null,
            minimized: this.allMinimized,
            coInsurance : this.plan.coinsurances ? this.plan.coinsurances[0] : '',
            totalPrice: 0,
            section:'',
            currentLang:window.globalLanguage.lang,
            coInsuranceFromLang: window.qResultsLang.no,
            deductible:this.plan.defaultDeductible || 0,
            frequency : '',
            affiliateVideo:ibJS.affiliateVideos,
            isCountrynotBlocked: window.globalLanguage.isCountryBlocked == "true"? false : true,
            deductibleNotAvailable: false,
            spouseDeductible: {},
            hasInitSpouseDeductible: false,
            isTravelOutsideSchengen: (window.ibJS.section === 'schengen' || window.ibJS.section === 'travelOutsideUSA' || window.ibJS.section === 'studyAbroad' || this.isCoverageAreaUsa===false ? true : false),
            showAcuteOnsetLink: true,
            isPlanDetailsVisible: false,
            isPriceBreakdownVisible: false,
            priceArray: [],
            priceIsSet: false,
            optionalCoveragesLabel: window.globalLanguage.optionalCoverage,
            piuCovid: this.ssCriteria?.piuCovidCheck,
            loading: false,
            primarySection: window.ibJS.section,
            showPriceBreakdown: true,
            planType: this.plan.planDetails.config.planType,
            planid: this.plan.planId
        }
    },
    watch: {
        allMinimized: function(value) {
            this.minimized = value;
        },
        deductible:function(value) {
            if(this.hasInitSpouseDeductible) {
                this.checkSpouseDeductible();
             }        
        },
        totalPrice (val) {
            
            if ([imgPlans.Patriot_America_Lite,
                imgPlans.Patriot_America_Platinum,
                imgPlans.Patriot_America_Plus,
                imgPlans.Patriot_International_Lite,
                imgPlans.Patriot_International_Platinum].indexOf(this.plan.planId) > -1) {
                    if (val < 10 && this.priceArray && this.priceArray.length) {
                        this.showPriceBreakdown = false;
                    } else {
                        this.showPriceBreakdown = true;
                    }
                    this.plan.hidePriceBreakdown = !this.showPriceBreakdown;
                }
        }
    },
    created: function () {
        // using global for work around with rendering summary and regular $emit not working
        if(global && global.eventHub){
            global.eventHub.$on('showModalFromSummary', this.showModal);
        }
        for (var key in window.globalSection){
            if(window.globalSection[key].indexOf(window.globalLanguage.section)>-1){
                this.section=key;
            }
        }
        this.initSpouseDeductibleMap();
        if(this.hasInitSpouseDeductible) {
            this.checkSpouseDeductible();
        }

    },
    mounted: function() {
        this.hideBestSellersBasedOnSection();
    },
    methods: {
        hideBestSellersBasedOnSection: function() {
            const excludedOutsideUSplans = [714, 1];
            if(excludedOutsideUSplans.indexOf(this.plan.planId) > -1 && ibJS.section === 'travelOutsideUSA') {
                this.plan.planDetails.config.bestseller = false;
            }
        },
        getComparePlan() {
            var thisPolicyMax = 0;
            // if(this.section ==='studentmedical'){
    		//      if(this.policyMax >= 100000 && this.policyMax <= 125000){
    		//                    policyMaxi=40;
    		//         } else if(this.policyMax >= 130000 && this.policyMax <= 175000){
    		//                    policyMaxi=50;
    		//         }
            // }

            if(!this.policyMax && this.$parent.policyMax) {
                thisPolicyMax = this.plan.policyMaximum;
            } else {
                thisPolicyMax = this.policyMax;
            }

            var plan = {
                planId: this.plan.planId,
                deductible: this.deductible,
                displayName: this.plan.planDetails.config.displayNameWSym,
                displayCode: this.plan.planDetails.config.displayCode,
                planColor: this.plan.planDetails.config.planColor,
                price: this.showPriceBreakdown ? this.totalPrice : '10',
                planType: this.plan.planDetails.config.planType,
                policyMax: thisPolicyMax,
                hidePriceBreakdown: this.plan.hidePriceBreakdown
            };
            if([712,713].indexOf(this.plan.planId) > -1) {
                plan.coInsurance = this.coInsurance;
            }
            return plan;
        },
        toggleCompare: function (value) {
            this.plan.compare = value;
            if (value) {
                var plan = this.getComparePlan();
                this.$emit('add-compare', plan);
            } else {
                this.$emit('remove-compare', this.plan.planId);
            }
        },
        showModal: function (url) {
            this.$emit('show-modal', url);
        },
        setPrice : function(value){ 
            this.priceIsSet = true;           
            // this.$set(this.plan, 'defaultTotalPrice', value);
            this.$emit('change-default-total-price', this.plan.planId, value);
            this.totalPrice=value;
        },
        setPolicyMax : function(value){
            this.policyMax=value;
            this.$set(this.plan, 'policyMaximum', value);
        },
        resetDeductible: function(value){
            this.$emit('reset-deductible',value);
        },
        cancelCompare: function(){
            this.$emit('remove-compare',this.plan.planId);
        },
        updateCompare: function() {
            setTimeout(() => {
                if(this.plan.compare) {
                    let plan = this.getComparePlan();
                    this.$emit('update-compare', plan);
                }
            }, 500);
        },
        setFrequency: function(value){
            this.frequency=value;
            this.plan.planBlock.paymentFrequencyStr = value;
        },
        setDeductible: function(value){
            this.deductible=value;
        },
        setCoInsurance: function(value){
            this.coInsuranceFromLang=value;
        },
        setCoinsuranceOption: function(value){
            this.coInsurance=value;
            this.$set(this.plan, 'coInsurance', value);
        },
        initSpouseDeductibleMap: function() {
            // init available deductible for spouse
            if(this.plan && this.plan.spouseItemPrices && this.plan.spouseItemPrices.length > 0) {
                for(let item of this.plan.spouseItemPrices) {
                    this.spouseDeductible[item.deductible] = true;
                }
                this.hasInitSpouseDeductible = true;
            }
        },
        checkSpouseDeductible: function() {
            if(this.spouseDeductible[this.deductible]) {
                this.deductibleNotAvailable = false;
            } else {
                this.deductibleNotAvailable = true;
            }
        },
        loadingPurchase() {
            this.loading = true;
            var spinTarget = this.$refs.purchaseButton;
            var spinner = new IBSpinner(ibSpinOpts).spin(spinTarget);
        },
        goToApp: function(){
            this.loadingPurchase();
            const allPlanIds = new PlanIds();
            if(this.plan.useOwnApp) {
                if(this.hasInitSpouseDeductible) {
                    this.checkSpouseDeductible();
                }
                if(this.deductibleNotAvailable) return;
                var form;
                //Trip Plans Trawick or iTI or Travelex
                if([506,583,634,635,636,728,729,689,725,726,727,756,757].indexOf(this.plan.planId) >-1 
                    || [361, 362, 580, 608, 590, 591, 592, 607, 585, 586, 588, 589, 584, 675, 676, 681, 662, 663, 664, 665, 666, 667, 668, 669].indexOf(this.plan.planId) > -1
                    || travelInsuredConfig.isTravelInsured(this.plan.planId)) {
                    form = {
                        planId:this.plan.planId,
                        companyCode: this.plan.planDetails.config.companyCode,
                        cancelForAnyReason: this.cancelAnyreason,
                        tripCriteria: {
                            departureDate: this.ssCriteria.departureDate,
                            returnDate: this.ssCriteria.returnDate,
                            initialTripDepositDate: this.ssCriteria.initialTripDepositDate,
                            primaryDestination: this.ssCriteria.primaryDestination,
                            residenceCountry: this.ssCriteria.residenceCountry,
                            citizenshipCountry: this.ssCriteria.citizenshipCountry,
                            travelerInfos: this.ssCriteria.travelerInfos,
                            region: this.ssCriteria.region
                        }
                    };
                    if ([506,583].indexOf(this.plan.planId) >-1) {
                        form.policyMaximum = 50000;
                    }
                } else if([671,672].indexOf(this.plan.planId) >-1){
                    form = {
                        planId:this.plan.planId,
                        companyCode: this.plan.planDetails.config.companyCode,
                        section: 'flight',
                        cancelForAnyReason: this.cancelAnyreason,
                        flightAccidentCriteria: {
                            departureDate: this.ssCriteria.departureDate,
                            returnDate: this.ssCriteria.returnDate,
                            numTravelers: this.ssCriteria.travelerInfos.length,
                            travelerInfos: this.ssCriteria.travelerInfos,
                            region: this.ssCriteria.region,
                            policyMaximum: this.ssCriteria.policyMaximum
                        }
                    };
                    
                } else {
                    form = {
                        startDate:this.ssCriteria.startDate,
                        endDate:this.ssCriteria.endDate,
                        primaryAge:this.ssCriteria.primaryAge,
                        spouseAge:this.ssCriteria.spouseAge,
                        section:this.section,
                        numChildren:this.ssCriteria.numChildren,
                        numChildren10_17:this.ssCriteria.numChildren10_17,
                        numChildrenUnder10:this.ssCriteria.numChildrenUnder10,
                        deductible:this.deductible,
                        policyMaximum:this.section === 'studentmedical' ? this.policyMax : this.plan.policyMaximum,
                        planId:this.plan.planId,
                        companyCode: this.plan.planDetails.config.companyCode,
                        primaryDestination: this.ssCriteria.primaryDestination,
                        mailingState: this.ssCriteria.mailingState,
                        residenceState: this.ssCriteria.residenceState,
                        travelerInfos: this.ssCriteria.travelerInfos,
                    };
                }

                if(this.coInsurance && [712,713].indexOf(this.plan.planId) > -1) {
                    form.coInsurance = this.coInsurance;
                }
                try{
                    window.sessionStorage.setItem("appFromQuoteResult",true);
                } catch(e){
                    console.log("Enable Cache");
                }
                // making sure the language is date format is english no matter what

                if(window.globalLanguage.lang != 'en'){
                    if(window.globalLanguage.lang == 'es'){
                        var startDate = convertDatei18n_es(form.startDate);
                        form.startDate = startDate;
                        var endDate = convertDatei18n_es(form.endDate);
                        form.endDate = endDate;
                    }
                    else{
                        var startDate = dayjs(form.startDate).format('MM/DD/YYYY');
                        form.startDate = startDate;
                        var endDate = dayjs(form.endDate).format('MM/DD/YYYY');
                        form.endDate = endDate;
                    }

                }

                // studyCountry
                //Hardcode img and gu apps lang as they aren't translated
                // check companyCode based on the displayName logic
                let companyCode = this.plan.planDetails.config.displayCode.split('_')[0];

                if(companyCode === 'IMG' && ['opt','j1Visa', 'newImmigrants'].indexOf(window.globalLanguage.section) < 0){
                    form.citizenshipCountry = this.ssCriteria.citizenshipCountry;
                    if(this.ssCriteria.homeCountry !== 'OTH'){
                        form.homeCountry = this.ssCriteria.homeCountry;
                    }
                }

                // pass studyCountry for IMG AND HCC student plans
                if(['IMG','HCC','WorldTrips'].indexOf(companyCode)>-1){
                    if(["studentmedical","opt","j1Visa",'student'].indexOf(window.globalLanguage.section)>-1){
                        form.studyCountry = this.ssCriteria.studyCountry || 'USA';
                    }
                }
                form.quoteId = window.ibJS.fullQuoteId ?  window.ibJS.fullQuoteId : '';
                setItemSessionStorage('fullQuoteId', window.ibJS.fullQuoteId || '');

                if (['IMG', 'ARS', 'GU'].indexOf(companyCode) > -1 || this.plan.planId == allPlanIds.worldtrips.VisitorSecure_Parent) {
                    const tempForm = JSON.parse(JSON.stringify(form));
                    if (this.plan.quotedDuration) tempForm.quotedDuration = this.plan.quotedDuration.replace(/\D/g,"");
                    setItemSessionStorage('plan-criteria', JSON.stringify(tempForm));
                }
                try {
                    const pageType = window.globalLanguage.section;
                    const fbEvent = {
                        "content_ids": [`${pageType}-${window.globalLanguage.lang}`],
                        "content_type": "product_group",
                        "currency": "USD",
                        "num_items": 1,
                        "contents": [{
                            "id": this.plan.planDetails.config.displayNameWSym, 
                            "quantity": 1
                        }],
                        "value": this.totalPrice
                    };
                    fbq('track', 'InitiateCheckout', fbEvent);
                } catch (error) {
                    console.error('error' , error);
                }
                if(['GU'].indexOf(companyCode) > -1 || [506,583,634,635,636,728,729,756,757].indexOf(this.plan.planId) > -1){
                    createUpdateApplicationService.call(this, '/api'+this.plan.planDetails.purchaseLink+'?lang=en', form, 'application', '/atlas-america/quote-results/?lang=en');
                }else {
                    createUpdateApplicationService.call(this, '/api'+this.plan.planDetails.purchaseLink, form, 'application', '/atlas-america/quote-results/?lang='+window.globalLanguage.lang);
                }
            } else{
                try {
                    //IB-4081
                    window.stop();
                }catch(e){}
                window.location.href=this.plan.planDetails.purchaseLink;
            }
        },
        minimize : function () {
            this.minimized = !this.minimized;
        },
        // removeCompare: function() {
        //     console.log('removeCompare plan block');
        //     this.$emit('remove-compare', this.plan.planId);
        // },
        splitRecurenceText: function(value) {
            
            this.value.split(":");
        },
        toggleMobileTab: function(tab) {
            if (tab === "details") {
                this.isPlanDetailsVisible = !this.isPlanDetailsVisible

                this.isPriceBreakdownVisible = false;

            } else if (tab === "price") {
                this.isPriceBreakdownVisible = !this.isPriceBreakdownVisible

                this.isPlanDetailsVisible = false;
            }
        },
        setPriceArray(value) {
            this.priceArray = value;
        },
        setPlanBlock(planId, planBlock) {
            this.$emit('set-plan-block', planId, planBlock);
        },
        openConactModal() {
            let element = $('#requestCallbackmodal');
            if(element) {
                $('#requestCallbackmodal').click();
            }
        },
        showMiddlePageModal
    },
    computed: {
        showPrice: function () {
            var priceShown = true;

            return priceShown;
        },
        planTypeModal () {
            let planType = this.section === 'flight' ? 'flightaccident' : this.section;
            return planType;
        }
    }
}
</script>
