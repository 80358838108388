<template>

    <div class="c-Print-plan__body">
        <div class="c-Print-plan__inner-h">
            <h3 v-html="plan.planDetails.config.displayNameWSym"></h3>
        </div>
        <div class="c-Print-plan__inner-info">
            <span v-if="section != 'trip' && section != 'flight' && !(section == 'j1Visa' && planList.length == 1 && plan.planId == 715)">
                <span v-if="isPPO">{{qResultsLang.yes}}</span>
                <span v-else>{{qResultsLang.no}}</span>
            </span>
            <span v-if="section != 'multitrip' && section != 'trip' && !(section == 'j1Visa' && planList.length == 1 && plan.planId == 715)" :style="{maxWidth: section == 'flight' ? 'initial' : ''}">{{ getPolicyMax }}</span>
            <span v-if="section != 'multitrip' && section != 'trip' && section != 'flight'  && !(section == 'j1Visa' && planList.length == 1 && plan.planId == 715)">{{ plan.planBlock ? plan.planBlock.deductible : plan.defaultDeductible | currencyNoCent }}</span>
            <span v-if="section == 'multitrip'">{{ getTripDates }}</span>
            <span v-if="(section == 'expatriate' || section == 'marine' || section == 'missionary') && plan.planBlock && plan.planBlock.paymentFrequencyStr">{{ plan.planBlock.paymentFrequencyStr }}</span>
            <span>{{ plan.defaultTotalPrice | currency }}</span>
        </div>
    </div>

</template>

<script>
    export default {
        props: {
            plan: {
                type: Object,
                default: () => {}
            },
            incrementDeductible: {
                type: Boolean,
                default: false
            },
            decrementDeductible: {
                type: Boolean,
                default: false
            },
            section: {
                type: String,
                default: ''
            },
            planList: {
                type: Array,
                default: () => []
            },
            primaryDestination: {
                type: String,
                default: 'USA'
            }
        },
        data:function(){
            return {
                deductible:this.plan.defaultDeductible,
                qResultsLang: window.qResultsLang || null
            }
        },
        watch: {
            incrementDeductible () {
                if(this.incrementDeductible){
                  var selectedIndex;
                  for(var i=0;i<this.plan.deductibles.length;i++){
                    if(this.deductible == this.plan.deductibles[i]){
                      selectedIndex = i;
                    }
                  }
                  if (selectedIndex < (this.plan.deductibles.length - 1)) {
                      this.deductible = this.plan.deductibles[selectedIndex+1];
                  }
                }
            },
            decrementDeductible () {
                if(this.decrementDeductible){
                  var selectedIndex;
                  for(var i=0;i<this.plan.deductibles.length;i++){
                    if(this.deductible == this.plan.deductibles[i]){
                      selectedIndex = i;
                    }
                  }
                  if (selectedIndex <= (this.plan.deductibles.length - 1) && selectedIndex!=0) {
                      this.deductible = this.plan.deductibles[selectedIndex-1];
                  }
                }
            }
        },
        computed:{
            getTripDates () {
                let tripDays = '';
                if (this.plan?.itemPrices && Array.isArray(this.plan.itemPrices)) {
                    for (let i = 0; i < this.plan.itemPrices.length; i++) {
                        const itemPrice = this.plan.itemPrices[i];
                        if (itemPrice.price === this.plan.defaultTotalPrice) {
                            tripDays = itemPrice.feature;
                            break;
                        }
                    }
                }
                return tripDays;
            },
            getPolicyMax () {
                let polMax = this.plan.policyMaximumText || this.$options.filters.currencyNoCent(this.plan.policyMaximum);
                if (this.plan.planId == 526) polMax = this.qResultsLang.unlimited;
                else if ((this.section == 'student' || this.section == 'j1Visa' || this.section == 'opt') && !this.plan.policyMaximum) {
                    if (this.plan.policyMaximums && this.plan.policyMaximums.length == 1) {
                        polMax = this.$options.filters.currencyNoCent(this.plan.policyMaximums[0]);
                    } else if (this.plan.policyMaximums && this.plan.policyMaximums.length > 1) {
                        const matchedList = this.plan.itemPrices.filter(plan=>plan.price === this.plan.defaultTotalPrice);
                        if (matchedList && matchedList.length) polMax = this.$options.filters.currencyNoCent(matchedList[0].policyMaximum);
                    }
                }
                return polMax;
            },
            isPPO() {
                if(this.primaryDestination == 'USA') {
                    return this.plan.planDetails.usaPPO;
                } else {
                    return this.plan.planDetails.internationalPPO;
                }
            }
        }
    }
</script>
