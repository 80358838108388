import {hideValidationsWhileTyping, getItemSessionStorage, removeKeySessionStorage, setItemSessionStorage} from '../../../helper-functions/services';
import { isChromeBrowser, checkAgent, isInPlanList} from '../../../helper-functions/helper-functions';
import { LoginModalMixin } from './loginModal';
import WTPlanIds from '../../../helper-functions/companies/worldtrips-planids';

const wtPlanIds = new WTPlanIds();

const hccConfig = {
    planId: ibJS.planId ? ibJS.planId : (ibJS.application ? ibJS.application.planId : ''),
    //Plan Lists
    allExchangeGuardPlans: [wtPlanIds.ExchangeGuard_Choice, wtPlanIds.ExchangeGuard_Essential],
    studentSecurePlans: [wtPlanIds.StudentSecure_Elite, wtPlanIds.StudentSecure_Select, wtPlanIds.StudentSecure_Budget, wtPlanIds.StudentSecure_Smart],
    excGuardGrpPlans: [wtPlanIds.ExchangeGuard_Group_Choice, wtPlanIds.ExchangeGuard_Group_Essential],
    //Plan List Functions
    isStudentSecure: function() { return isInPlanList(this.studentSecurePlans, this.planId) },
    isExchangeGuard: function() { return isInPlanList(this.allExchangeGuardPlans, this.planId) },
    isExchangeGuardGrp: function() { return isInPlanList(this.excGuardGrpPlans, this.planId) }
  };

//Applies to step 1 on all applications.
export default {
    data: function() {
       return {
           form:{
                startDate: this.formData.startDate || '',
                endDate: this.formData.endDate || '',
                travelerInfos: this.formData.travelerInfos,
                planId: ibJS.planId
            },
            showTravelers: false,
            showSummary: false,
            travelersOpened: false,
            application: ibJS.application || null,
            showChildErrorText:false,
            showOtherErrorText: false,
            hideDateError: false,
            clickedDelete: false,
            isFromQuoteResults: false,
            stopRequireChildrenAndOthers: false,
            isChrome: isChromeBrowser(),
            isLoggedIn: ibJS?.affiliateValues?.myAccountLogin || false
       }
    },
    mixins: [
        LoginModalMixin
    ],
    created: function() {
        this.checkIfFromQuoteResults();

        // Logic for all Family type applications IMG, ARS, GU, Visitors Secure
        // When the new QF passes multiple travelers including second adult then the App object will be empty
        let qrFrmData = getItemSessionStorage('plan-criteria');
        if (!this.form.startDate && !this.form.endDate && qrFrmData) {
            qrFrmData = JSON.parse(qrFrmData);
            if (this.form.hasOwnProperty('startDate') && qrFrmData.startDate) this.form.startDate = qrFrmData.startDate;
            if (this.form.hasOwnProperty('endDate') && qrFrmData.endDate) this.form.endDate =  qrFrmData.endDate;
            if (this.form.hasOwnProperty('LTNumberOfMonths') && qrFrmData.quotedDuration) this.form.LTNumberOfMonths =  qrFrmData.quotedDuration;
            if (this.form.hasOwnProperty('citizenshipCountry') && qrFrmData.citizenshipCountry) this.form.citizenshipCountry = qrFrmData.citizenshipCountry;
            if (this.form.hasOwnProperty('homeCountry') && qrFrmData.countryOfResidence) this.form.homeCountry = qrFrmData.countryOfResidence;
            if (this.form.hasOwnProperty('primaryAndSpouseArray') 
                && qrFrmData.travelerInfos && qrFrmData.travelerInfos.length === 2 && qrFrmData.companyCode === 'GU') {
                const travelers = qrFrmData.travelerInfos.filter(item => item.age < 18);
                if (!travelers.length) {
                    this.form.travelerInfos = [{
                        age: qrFrmData.travelerInfos[0].age, 
                        travelerType: 'primary'
                    }, {
                        age: qrFrmData.travelerInfos[1].age, 
                        travelerType: 'spouse'
                    }];
                }
            }
            setItemSessionStorage('plan-criteria-stp2', JSON.stringify({policyMaximum: qrFrmData.policyMaximum, deductible: qrFrmData.deductible}));
            removeKeySessionStorage('plan-criteria');
        }
    },
    mounted: function() {
       checkAgent.call(this, [true], true);

        const validationsToHide = ['start', 'end'];
        hideValidationsWhileTyping(validationsToHide);
        setTimeout(() => {
            try {
                if (ibJS.planConfig && ibJS.planConfig.displayNamePlain && typeof fbq === 'function') {
                    const pageType = ibJS.planConfig.displayNamePlain;
                    const fbEvent = {
                        "content_ids": [`${pageType}-${window.globalLanguage.lang}`],
                        "num_items": 1,
                        "content_type": "product",
                        "currency": "USD"
                    };
                    fbq('track', 'InitiateCheckout', fbEvent);
                } 
            } catch (error) {
                console.error('error' , error);
            }
        }, 3000);
    },
    methods: {
        checkIfFromQuoteResults: function() {
            if(ibJS && ibJS.application) {
                for(var i=0; i < ibJS.application.travelerInfos.length;i++){
                    if(ibJS.application.travelerInfos[i].age === -1){
                        this.isFromQuoteResults = true;
                        break;
                    }
                }
            }
        },
        getTravelerArray: function() {
            let arr = [];
            const admin = ibJS.backEndPlan.config.administrator;
            if(admin === 'Seven Corners' || admin === 'Global Underwriters') {
                arr = [...this.form.primaryAndSpouseArray];
                arr = arr.concat(this.form.childrenListArray)

                if(this.form.othersListArray) {
                    arr = arr.concat(this.form.othersListArray);
                }
            }
            else {
                arr = this.form.travelerInfos;
            }

            return arr;
        },
        checkIfMoreThanOneOfType(travelerType) {
            let counter = 0;

            let arr = this.getTravelerArray();

            for(let i=0; i < arr.length; i++) {
                let tvler = arr[i];
                if(tvler.travelerType === travelerType) {
                    counter++;
                    if(counter > 1) {
                        return true;
                    }
                }
            }
            return false;
        },
        deleteTraveler: function($index) {
            this.clickedDelete = true;
            this.showChildErrorText = false;
            this.showOtherErrorText = false;
            if(this.showMobileError) {
                this.showMobileError = false
            }

            if(hccConfig.isExchangeGuardGrp()) {
                this.allAgeGroups.splice($index, 1);
                this.form.groupTravelersInfo.splice($index, 1);
            } else {
                this.form.travelerInfos.splice($index, 1);
            }
        },
        deleteChild: function($index){
            this.clickedDelete = true;
            this.showChildErrorText = false;

            if(this.form.childrenListArray) {
                this.form.childrenListArray.splice($index,1);
            }
            else {
                this.form.travelerInfos.splice($index, 1);
            }

            if(this.sumChildren) {
                this.sumChildren(false);
            }
        },
        deleteOther: function($index){
            this.clickedDelete = true;
            this.showOtherErrorText = false;

            if(this.form.othersListArray) {
                this.form.othersListArray.splice($index,1);
            }
            else {
                this.form.travelerInfos.splice($index, 1);
            }
        },
        spliceTravelers: function(optionalArray) {
            let arr = optionalArray || this.form.travelerInfos

            for (var i = 1; i < arr.length; i++) {
                if (arr[i].age === null || arr[i].age === '') {
                    arr.splice(i, 1);
                    i -= 1;
                }
            }

        },
        showGeoModal: function (e, url=null) {
            if (url !== null && url.indexOf('$PLANID') > -1) {
                url = url.replace('$PLANID', e.target.getAttribute('data-plan-id'));
                url = url.replace('$PLANTYPE', e.target.getAttribute('data-plan-type'));
                url = url.replace('$CURRENTLANG', e.target.getAttribute('data-current-lang'));
            }
            this.showModal(url);
        },
        toggleShowSummary: function() {
            this.showSummary ? this.showSummary = false : this.showSummary = true;
        },
        showResetApplicationModal: function(e) {
            this.showModal('/guide/terms?section=confirmstartover');
            e.preventDefault();
        },
        showLoginModal: function() {
              this.requestLoginModal();
              sessionStorage.setItem("isShowLogin", true);
        }
    }
}