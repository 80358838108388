import { getItemSessionStorage, removeKeySessionStorage, setItemSessionStorage } from '../../helper-functions/services';
import { convertDateByLang, getParameterByName, isSchengenCountry } from '../../helper-functions/helper-functions';

export default {
    data() {
        return {
            qFormLang: window.qFormLang,
            skipSteps: {
                j1Visa: [1,2,3],
                opt: [1,2,3,4,5,6,7,8,9,10, 11],
                student: []
            }
        }
    },
    created() {
        // Determining which data should be prefilled in the Quote Form (QF):
        // Scenarios: The form can be prefilled from the consolidated landing page, retrieved from session storage upon refresh, 
        // or loaded from an existing quote during "Edit Quotes."
        //
        // 1. If this is the first time the Quote Form (QF) is rendered from the consolidated landing page, 
        //    then prefer session storage values and ignore all other sources.
        //
        // 2. If a "presection" parameter is present in the URL, use its associated default values to prefill the form.
        //
        // 3. If both the session storage values and the "presection" parameter exist:
        //      a. Compare the "section" value stored in session storage with the "presection" parameter from the URL.
        //      b. If they match, use the session storage values for pre-filling.
        //      c. If they differ, clear all session storage values (including "currentStep") and prefill the form using 
        //         the default values associated with the "presection." Then, update session storage with the new "currentStep."

        let ssCriteria;

        const landingPageMiniQuote = getItemSessionStorage('insubuy-student-quote-form-resultObjs');
        const existingFormValues = getItemSessionStorage('insubuy-student-quote-Criteria');
        const section = getItemSessionStorage('student-quote-form-section');
        const URLpresection = getParameterByName('presection');
        let sessionCriteriaData;
        const allThreeCriterias = ['insubuy-j1VisaCriteria', 'insubuy-studentCriteria', 'insubuy-optCriteria'];
        for (let i = 0; i < allThreeCriterias.length; i++) {
            const thisCriteria = getItemSessionStorage(allThreeCriterias[i]);
            if (thisCriteria) {
                sessionCriteriaData = JSON.parse(thisCriteria);
                if (allThreeCriterias[i] === "insubuy-optCriteria") {
                    sessionCriteriaData.section = 'opt';
                    sessionCriteriaData.visaStatus = 'F1';
                    sessionCriteriaData.isOPT = true;
                } else if (allThreeCriterias[i] === "insubuy-studentCriteria") {
                    sessionCriteriaData.section = 'student';
                    sessionCriteriaData.visaStatus = sessionCriteriaData.f1Insurance ? 'F2' : 'F1';
                } else if (allThreeCriterias[i] === "insubuy-j1VisaCriteria") {
                    sessionCriteriaData.section = 'j1Visa';
                    sessionCriteriaData.visaStatus = sessionCriteriaData.f1Insurance ? 'J2' : 'J1';
                }
                removeKeySessionStorage(allThreeCriterias[i]);
                break;
            }
        }

        if (sessionCriteriaData && !ibJS.isResultsPage) {
            // Expired Quote Form / Retrieve Quotes
            ssCriteria = sessionCriteriaData;
            ssCriteria.startDate = "";
            ssCriteria.endDate = "";
            const currenSteps = {
                currentStep: 11,
                completedSteps: this.skipSteps.opt,
            };
            setItemSessionStorage('student-quote-step', JSON.stringify(currenSteps));
        } else if (landingPageMiniQuote) {
            // Student Landnig Page
            const miniQuoteData = JSON.parse(landingPageMiniQuote);
            if (miniQuoteData.presection === 'student') {
                ssCriteria = {};
                ssCriteria.studyCountry = miniQuoteData.primaryDestination;
                ssCriteria.startDate = miniQuoteData.startDate;
                ssCriteria.endDate = miniQuoteData.endDate;
                ssCriteria.schoolName = miniQuoteData.schoolName;
                ssCriteria.schoolId = miniQuoteData.schoolId;
                ssCriteria.citizenshipCountry = miniQuoteData.citizenshipCountry;
                ssCriteria.section = miniQuoteData.section;
                ssCriteria.isOPT = miniQuoteData.isOPT;
                ssCriteria.visaStatus = miniQuoteData.visaStatus;

                let steps = {
                    currentStep: 3,
                    completedSteps: [0,1,2,3]
                }

                if (ssCriteria.studyCountry && isSchengenCountry(ssCriteria.studyCountry)) {
                    // Start with Schengen Visa Requirement
                    steps.currentStep = 2;
                    steps.completedSteps = [0,1,2];
                    ssCriteria.section = "student";
                } else if (ssCriteria.studyCountry && ssCriteria.studyCountry != 'USA') {
                    // Start with citizenship
                    steps.currentStep = 9;
                    steps.completedSteps = [0,1,2,3,4,5,6,7,8,9];
                    ssCriteria.section = "student";
                }
                
                // Set currentstep in sessionStorage. Step 11 = date
                const currenSteps = {
                    currentStep: ssCriteria.section === 'opt' ? 11 : steps.currentStep,
                    completedSteps: ssCriteria.section === 'opt' ? this.skipSteps.opt : steps.completedSteps,
                };
                setItemSessionStorage('student-quote-step', JSON.stringify(currenSteps));
                
                removeKeySessionStorage('insubuy-student-quote-form-resultObjs');
                removeKeySessionStorage('insubuy-student-quote-Criteria');
                removeKeySessionStorage('student-quote-form-section');
            }
        } else if(existingFormValues && (URLpresection === section || !URLpresection) && !ibJS.isResultsPage) {
            // Refresh Quote Form
            ssCriteria = JSON.parse(existingFormValues);
            ssCriteria.section = section;
            if(window.globalLanguage.lang != 'en') convertDateByLang(ssCriteria, window.globalLanguage.lang);
        } else if (URLpresection) {
            // With URL query parameters
            ssCriteria = {};
            switch (URLpresection) {
                case "student":
                    ssCriteria.studyCountry = "USA";
                    ssCriteria.isOPT = null;
                    ssCriteria.section = 'student';
                    break;
                case "j1Visa":
                    ssCriteria.studyCountry = "USA";
                    ssCriteria.section = 'j1Visa';
                    break;
                case "opt":
                    ssCriteria.studyCountry = "USA";
                    ssCriteria.visaStatus = "F1";
                    ssCriteria.section = 'opt';
                    ssCriteria.isOPT = true;
                    break;
                default:
                    ssCriteria.studyCountry = "USA";
                    break;
            }

            // Set currentstep in sessionStorage.
            const currenSteps = {
                currentStep: ssCriteria.section === 'opt' ? 11 : 3,
                completedSteps: ssCriteria.section === 'opt' ? this.skipSteps.opt : [0,1,2,3],
            };
            setItemSessionStorage('student-quote-step', JSON.stringify(currenSteps));

            removeKeySessionStorage('insubuy-student-quote-Criteria');
            removeKeySessionStorage('student-quote-form-section');
        }

        
        if (ssCriteria) this.ssCriteria = ssCriteria;
        
        // Get if office is open.
        GlobalVue.getCurrentCSTTime();
    },
    methods: {
        closeServerError() {
            this.server500ErrorShown = false;
        },
        closeNoPlanError() {
            this.noPlansFoundShown = false;
        },
        closeGlobalError() {
            this.showGlobalErrors = false;
        },
        resetNarrowFilter: function(){
            this.resetNarrow();
        },
    }
}