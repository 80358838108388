import { LoginModalMixin } from './loginModal';
import {checkAgent} from '../../../helper-functions/helper-functions';


//Applies to step 4 on all applications.
export default {
    data() {
        return {
            currentLang: window.globalLanguage.lang,
            totalPrice: '',
            isLoggedIn: ibJS?.affiliateValues?.myAccountLogin || false
        }
    },
    mixins: [
        LoginModalMixin
    ],
    created: function() {
        this.calculateShipping();
    },
    mounted: function() {
        checkAgent.call(this, [true]);
        setTimeout(() => {
            try {
                if (typeof fbq === 'function') {
                    const fbEvent = {
                        "content_ids": [`${ibJS.application.planName}-${window.globalLanguage.lang}`],
                        "content_name": ibJS.application.planName,
                        "num_items": 1,
                        "content_type": "product",
                        "currency": "USD",
                        "value": ibJS.application.totalPrice
                    };
                    fbq('track', 'SubmitApplication', fbEvent);
                } 
            } catch (error) {
                console.error('error' , error);
            }
        }, 3000);
    },
    methods: {
        calculateShipping: function(){
            if(this.application.fulfillmentType=='1'){
                this.shippingCharges=20;
            } else if(this.application.fulfillmentType=='4'){
                this.shippingCharges=30;
            }
        }
    },
    computed: {
        planResultQuoteDuration () {
            return this.application?.planResult?.quotedDuration || null;
        }
    }
};