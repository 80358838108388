import Vue from 'vue';

export default {
    props: {
        content: String,
    },
    template: '<div ref="summary"></div>',
    mounted: function () {
        var CompileSummary = Vue.extend({
            data: function () {
                return {
                    showPPO:true,
                    addMargin: false
                }
            },
            created: function(){
                if(ibJS.section) {
                    this.addMargin = true;
                }
            },
            template: `<div class="c-Plan-details__text">
                        ${this.content}</div>`,
            methods: {
                showModal: function (url) {
                    // had to use global because regular emit was not working for this
                    global.eventHub.$emit('showModalFromSummary', url);
                },
                showMiddlePageModal () {
                    global.eventHub.$emit('showMiddlePageModal');
                }
            }
        });

        new CompileSummary().$mount(this.$refs['summary']);
    }
};
