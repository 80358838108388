require('../../../main');

import Vue from 'vue';
import VueResource from 'vue-resource';
Vue.use(VueResource);

var accounting = require('accounting');
var Clipboard = require('clipboard');
import MainVue from '../../../main-vue';
import datePicker from '../../../components/forms/component.date-picker.vue';
import { 
    GoAwayMixin
 } from '../../../section-specific/applications/mixins/GoAwayCapture';

import { sessionKeysToRetain } from '../../../helper-functions/constants-configs';
import { 
    setTimeOutFun, 
    resetExpirationTimer,
} from '../../../helper-functions/helper-functions';

$(document).ready(function() {

    $('.c-ibApp').on('cut copy paste', function (e) {
        // allow cut copy paste on entire application
        e.stopPropagation();
    });

    $('#app').on('cut copy paste', function (e) {
        // allow cut copy paste on entire application
        e.stopPropagation();
    });

    $('#confirmEmail').on('copy paste', function (e) {
        // disabling copy paste in email field
        alert(window.qResultsLang.noCopyEmail);
        return false;
    });
});

Vue.filter('currency', function (value) {
    if (value === null || value === undefined) {
        return '';
    } else {
        accounting.settings = {
            currency: {
                symbol : "$",   // default currency symbol is '$'
                format: "%s%v", // controls output: %s = symbol, %v = value/number (can be object: see below)
                decimal : ".",  // decimal point separator
                thousand: ",",  // thousands separator
                precision : 2   // decimal places 0 for none
            },
            number: {
                precision : 0,  // default precision on numbers is 0
                thousand: ",",
                decimal : "."
            }
        }
        return accounting.formatMoney(value) + ' ' + window.qResultsLang.USD;
    }
});

Vue.filter('currencyNoCent', function (value) {
    if (value === null) {
        return '';
    } else {
        accounting.settings = {
            currency: {
                symbol : "$",   // default currency symbol is '$'
                format: "%s%v", // controls output: %s = symbol, %v = value/number (can be object: see below)
                decimal : ".",  // decimal point separator
                thousand: ",",  // thousands separator
                precision : 0   // decimal places 0 for none
            },
            number: {
                precision : 0,  // default precision on numbers is 0
                thousand: ",",
                decimal : "."
            }
        }
        return accounting.formatMoney(value);
    }
});

Vue.filter('translate',function (value,language=window.globalLanguage.lang) {

    //used to quickly translate the english date into language specific date. The value will always be a english date

    if(language === 'zh'){
        let startDate = new Date(value);
        let month = startDate.getMonth() + 1;
        let date  = startDate.getDate();
        if(month < 10) {
            month = "0"+month;
        }
        if(date < 10) {
            date = "0"+date;
        }
        let formatDate = startDate.getFullYear() + '-' + month + '-' + date;
        return formatDate;
    }
    else if (language === 'es'){
        let startDate = value.split('/');
        startDate = new Date(startDate[2], startDate[0] - 1, startDate[1]);
        let month = startDate.getMonth() + 1;
        let date  = startDate.getDate();
        if(month < 10) {
            month = "0"+month;
        }
        if(date < 10) {
            date = "0"+date;
        }
        let formatDate = date + '/' + month + '/' + startDate.getFullYear();
        return formatDate;
    }
    else{
        return value;
    }
});


new MainVue({
    el: '#app',
    components: {
        'date-picker': datePicker
    },
    mixins: [
        GoAwayMixin,
    ],
    data: function () {
        return {
            formData: {
                id: null,
                startDate: ibJS.application ? ibJS.application.startDate : '',
                endDate: ibJS.application ? ibJS.application.endDate : '',
                travelerInfos: [
                    {
                        age: null,
                        tripCost: null,
                        quotePrice: null,
                        travelerType: ''
                    },
                    {
                        age: null,
                        tripCost: null,
                        quotePrice: null,
                        travelerType: ibJS.planId != 714 ? '' : 'spouse'
                    },
                    {
                        age: null,
                        tripCost: null,
                        quotePrice: null,
                        travelerType: ibJS.planId != 714 ? '' : 'child'
                    }
                ],
                planId: ibJS.planId,
                policyMaximum: '',
                deductible: '',
                riderList: [],
                adndCoverage: null,
                crisisCoverage: null,
                liabilityCoverage: null,
                displayLanguageMessage: false
            },
            planData: {},
            currentStep: ibJS.currentStep,
            errors: ibJS.errors,
            spinOpts: {},
            qResultsLang: window.qResultsLang || '',
            showSessionTimer: false,
            sessionExtensionTimer: null,
            dates: null,
            timerInterval: null,
            isInternalIP: window.globalLanguage.internalIP,
            disableSessionTimer: false
        }
    },
    created: function () {
        this.clearSessionStorageFromOtherPlans();

        this.setTimeOutFun();

        if (window.globalLanguage.lang !== 'en') {
            this.formData.displayLanguageMessage = true;
        }


        if (this.currentStep == '2' && ibJS.application) {
            var self = this;

            var appId = ibJS.application.id;
            self.$http.get('/api/application/plan-results/', {params: {applicationId: appId}})
                .then(function (response) {
                        if (response) {
                            var responseBody = response.body;

                            if (responseBody.status === 'error' && responseBody.data) {
                                if (responseBody.data.refresh) {
                                    window.location.href = responseBody.data.redirectTo;
                                    return;
                                }
                                if (responseBody.data.globalErrors) {
                                    self.globalErrors = responseBody.data.globalErrors;
                                }
                                if (responseBody.data.fieldErrors) {
                                    self.fieldErrors = responseBody.data.fieldErrors;
                                }
                            } else {
                                // Sentry - INSUBUY-2C1
                                // if for some reason the backend return empty data, assign the 
                                // new object to the planData
                                var planData = response.body.data || {};
                                
                                self.planData = planData;
                                self.dataFetched = true;
                            }
                        }
                    },
                    function (response) {
                        if (response) {
                            var responseBody = response.body;
                            var errors = {};
                            if (responseBody !== null && responseBody !== undefined) {
                                errors.serverErrors = responseBody.data;
                                self.showServer500Error(errors);
                                $("body").animate({scrollTop: 0}, "fast");
                                self.dataFetched = true;
                            }
                        }
                    });

            var self = this;
            self.windowWidth = window.innerWidth;

            // Throttle window resize function call
            var delay = 200
                , run
                , runBuffer
                , tid
            ;

            run = function () {
                // This is the part we're most concerned with
                self.windowWidth = window.innerWidth;
            };

            runBuffer = function () {
                clearTimeout(tid);
                tid = setTimeout(function () {
                    run();
                }, delay);
            };

            window.addEventListener('resize', runBuffer);
        }

        global.eventHub.$on('reset-ss-error', this.resetSSErrors);
    },
    mounted: function () {
        //Make English only message sticky window.pageYOffset
        let errorMessage = document.getElementsByClassName('c-ibApp__form-top-red-message')[0]
        let mainWrap = document.getElementsByClassName('c-ibApp__form-main-wrap')[0] || document.getElementsByClassName('c-ibApp__form-h')[0];
        let main = document.getElementsByClassName('c-ibApp__form-main-wrap')[0] || document.getElementsByClassName('c-ibApp__form-intro')[0];

        if (errorMessage && mainWrap && main) {
            window.addEventListener('scroll', () => {
                let topDistance = main.offsetTop - errorMessage.offsetHeight;
                if (window.pageYOffset > topDistance) {
                    //make sticky
                    errorMessage.style.top = '0px';
                    errorMessage.style.position = 'fixed';
                    errorMessage.style.width = mainWrap.offsetWidth + 'px';
                } else {
                    errorMessage.style.position = 'static';
                    errorMessage.style.top = '';
                }
            })

            window.addEventListener('resize', () => {
                errorMessage.style.width = mainWrap.offsetWidth + 'px';
            });
        }

        let _bckToBtn = document.querySelectorAll(".app_returnToQuotes");
        const fullQuoteId = sessionStorage.getItem('fullQuoteId');
        if (_bckToBtn 
            && fullQuoteId
            && this.currentStep !== '6') {
            _bckToBtn = Array.from(_bckToBtn);
            _bckToBtn.forEach(btn => {
                if (btn && btn.classList && btn.classList.contains('u-Hidden')) {
                    btn.classList.remove("u-Hidden");
                    btn.querySelector('a').href+=fullQuoteId;
                }
            });
        }
    },
    computed: {
        mins: function () {
            return this.dates.format('mm');
        },
        secs: function () {
            return this.dates.format('ss');
        }
    },
    methods: {
        resetSSErrors: function () {
            this.errors = [];
        },
        clearSessionStorageFromOtherPlans: function () {
            if (!window.navigator.cookieEnabled) return // sessions storage disabled.
            //APP-1302
            let planId = ibJS.planId || (ibJS.application ? ibJS.application.planId : null);

            // iTI
            if(ibJS.imgTripPlan && ibJS.application) {
                planId = ibJS.application.planId;
            }

            // Travelex
            let keepValidatedAddress = false;
            let validatedState = '';
            if(ibJS.application && ibJS.planConfig.companyCode === 'Travelex') {
                planId = ibJS.application.planId;
                if(sessionStorage.getItem('addressValidated') === 'true') {
                    keepValidatedAddress = true;
                    validatedState = sessionStorage.getItem('validatedState');
                }
            }

            let appId;

            if (ibJS.application) {
                appId = ibJS.application.id;
            }

            if (!sessionStorage.getItem('appId') && appId) {
                sessionStorage.setItem('appId', appId);
            }

            if (sessionStorage.getItem('currentApplicationPlanID') != planId ||
                this.currentStep === '6' ||
                appId !== +sessionStorage.getItem('appId')) {

                for (let key in sessionStorage) {
                    /* keep safeTreker session storage for normal and plus plan switch use,
                         will manually delete in trawick code*/
                    if(key == 'safeTrekerSession') {
                        return;
                    };
                    if (sessionKeysToRetain.indexOf(key) === -1 && sessionStorage.hasOwnProperty(key)) {
                        if (new RegExp(/^insubuy-/).test(key) === false 
                            && new RegExp(/^sort/).test(key) === false 
                            && key !== 'captureModalEvent'
                            && key !== 'validatedState') {
                            sessionStorage.removeItem(key);
                        }
                    }
                }
            }

            sessionStorage.setItem('appId', appId);
            sessionStorage.setItem('currentApplicationPlanID', planId);
            
            // reload for Travelex
            if(ibJS.application && ibJS.planConfig.companyCode === 'Travelex') {
                sessionStorage.setItem('validatedState', validatedState);
                sessionStorage.setItem('addressValidated', keepValidatedAddress);
            }
        },
        setTimeOutFun: function () {
            setTimeOutFun.call(this, false);
        },
        resetSessionExpireTime: function () {
            resetExpirationTimer.call(this);
        },
        closeTimeout: function () {
            this.showSessionTimer = false;
            this.disableSessionTimer = true;
        }
    }
});
