import WorldTrips from "./companies/worldtrips-planids";
import IMG from "./companies/img-planids";
const worldTrips = new WorldTrips();
const img = new IMG();

export const showGeoModal = function (e, url) {
    var url = url || null;
    let target = e.target;
    if (!target.dataset || (target.dataset && !target.dataset.planId)) target = e.currentTarget;
    if (url != null && url.indexOf('$PLANID') > -1 && e && target && target.dataset) {
        url = url.replace('$PLANID', target.dataset.planId);
        url = url.replace('$PLANTYPE', target.dataset.planType);
        url = url.replace('$CURRENTLANG', target.dataset.currentLang);
    }
    this.showModal(url);
}

export const showTermsModal = function (e, url) {
    let target = e.currentTarget || e.target;
    const isOptionialCoverage = target.dataset.optionalCoverages ? (target.dataset.optionalCoverages == 'true') : false;
    if (!target.dataset || (target.dataset && !target.dataset.planId)) target = e.currentTarget;
    var url = url || null;
    if (url != null && (url.indexOf('$PLANID') > -1 || url.indexOf('$SECTION') > -1) && e && target && target.dataset) {
        url = url.replace('$PLANID', target.dataset.planId);
        url = url.replace('$CURRENTLANG', target.dataset.currentLang);
        url = url.replace('$SECTION', target.dataset.section);
    }
    if (isOptionialCoverage) url += '&termsModal=true';
    this.showModal(url);
}

export const showMiddlePageModal = function (e, url) {
    let target = e.target;
    const label = target.dataset.label;
    var url = target.getAttribute('href');
    const planLanding = typeof window.singlePlan != 'undefined' && window.singlePlan ? true : false;
    const studentInsurance = [
        worldTrips.ExchangeGuard_Choice,
        worldTrips.ExchangeGuard_Essential,
        worldTrips.ExchangeGuard_Group_Choice, 
        worldTrips.ExchangeGuard_Group_Essential,
        worldTrips.StudentSecure_Budget,
        worldTrips.StudentSecure_Select,
        worldTrips.StudentSecure_Smart,
        worldTrips.StudentSecure_Elite,
        img.Student_Health_Advantage_Standard,
        img.Student_Health_Advantage_Platinum
    ];
    const planId = !planLanding ? +this.planid : +target.dataset.planId;
    const planType = !planLanding ? +this.planType : +target.dataset.planType;
    if (url != null && label === 'Plan Benefits' 
            && (
                (planType == 1 && [img.Visitors_Care_Plus, img.Visitors_Care_Lite, img.Visitors_Care_Platinum].indexOf(planId) === -1) || 
                (
                    [
                        worldTrips.ExchangeGuard_Parent,  
                        worldTrips.ExchangeGuard_Group_Parent, 
                        worldTrips.StudentSecure_Parent,
                        img.Student_Health_Advantage_Parent,
                        ...(ibJS.isResultsPage ? [] : studentInsurance)
                    ].indexOf(planId) > -1 && (globalSection.studentmedical.indexOf(globalLanguage.section) > -1 || planLanding)
                )
            )
        ) {
        e.preventDefault();
        global.eventHub.$emit('req-method', 'post');
        this.showModal(url);
    }
}